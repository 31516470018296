import React, { isValidElement, cloneElement, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidV1 } from 'uuid';

import styleAutocomplete from './utils/style-autocomplete';
import { getDefaultValue } from './utils';

import icons from '../../helpers/Icons';
import { Field } from '../input';
import { AutocompletePopover } from './AutocompletePopover';
import useAutocomplete from './hooks/useAutocomplete';
import useOutsideClick from '../../hooks/useOutsideClick';

const UnStyledAutocomplete = ({
    className,
    fluid,
    id,
    name,
    label,
    placeholder,
    defaultValue,
    disabled,
    async,
    loading,
    options,
    error,
    actionView,
    onChange,
    onSelect,
    onHide,
    loadOutsideClick,
    searchIcon = false,
    resetDefaultValue,
    onClick,
    displayCascade
}) => {
    const ref = useRef(null);
    const { shown, hasFocus, selectedOption, matchingOptions, setShown, handleOptionClick, handleChange, handleBlur, handleFocus } = useAutocomplete({
        async,
        options,
        actionView,
        onChange,
        onSelect,
        onHide: handleHide,
        resetDefaultValue
    });
    useOutsideClick(
        ref,
        () => {
            setShown(false);
        },
        loadOutsideClick
    );

    const defaultId = useMemo(() => id, []);

    function handleActionViewClick() {
        setShown(false);
        actionView.props.onClick();
        handleHide();
    }

    function handleHide() {
        if (onHide) onHide();
    }

    const actionViewClone = isValidElement(actionView) && cloneElement(actionView, { onClick: handleActionViewClick });

    return (
        <div ref={ref} className={className}>
            <div>
                <Field
                    id={defaultId}
                    fluid={fluid}
                    type='text'
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    defaultValue={getDefaultValue(selectedOption, defaultValue, hasFocus)}
                    disabled={disabled}
                    error={error}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    searchIcon={searchIcon}
                    onClick={onClick}
                />
                {searchIcon && (
                    <div className='icon'>
                        <img src={icons.pictoSearch} alt='' />
                    </div>
                )}
            </div>
            {shown && <AutocompletePopover loading={loading} options={matchingOptions} actionView={actionViewClone} onOptionClick={handleOptionClick} displayCascade={displayCascade} />}
        </div>
    );
};

const StyledAutocomplete = styleAutocomplete(UnStyledAutocomplete);

export const Autocomplete = ({ id = uuidV1(), fluid = false, disabled = false, async = true, loading = false, loadOutsideClick = true, options = [], ...restProps }) => {
    return <StyledAutocomplete id={id} fluid={fluid} disabled={disabled} async={async} loading={loading} options={options} loadOutsideClick={loadOutsideClick} {...restProps} />;
};
Autocomplete.propTypes = {
    fluid: PropTypes.bool,
    loadOutsideClick: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    async: PropTypes.bool,
    loading: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any
        })
    ),
    error: PropTypes.string,
    actionView: PropTypes.node,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    onHide: PropTypes.func,
    searchIcon: PropTypes.bool,
    resetDefaultValue: PropTypes.bool,
    onClick: PropTypes.func,
    displayCascade: PropTypes.node
};
