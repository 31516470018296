import React from 'react';
import PropTypes from 'prop-types';

import styleActionIcon from './utils/style-action-icon';
import saveIcon from '../../assets/img/buttons/save.svg';
import sendIcon from '../../assets/img/buttons/send.svg';
import printIcon from '../../assets/img/buttons/print_secondary.svg';
import previewIcon from '../../assets/img/buttons/preview_secondary.svg';
import deleteIcon from '../../assets/img/buttons/delete_filled.svg';

const icons = {
    save: saveIcon,
    send: sendIcon,
    print: printIcon,
    preview: previewIcon,
    delete: deleteIcon
};

const UnStyledActionIcon = ({ className, name }) => {
    return <img className={className} src={icons[name]} alt='' />;
};

const StyledActionIcon = styleActionIcon(UnStyledActionIcon);

export const ActionIcon = ({ name = 'save', ...restProps }) => {
    return <StyledActionIcon name={name} {...restProps} />;
};
ActionIcon.propTypes = {
    name: PropTypes.oneOf(Object.keys(icons))
};
