import React from 'react';
import styled from 'styled-components';

const Password = ({ testId, id, label, placeholder, name, dataCy, disabled, required, onChange, defaultValue }) => {
    return (
        <input
            data-testid={testId}
            data-cy={dataCy}
            id={id}
            type='password'
            placeholder={placeholder}
            name={name}
            disabled={disabled}
            required={required}
            defaultValue={defaultValue}
            onChange={({ target }) => onChange({ label, name, value: target.value })}
        />
    );
};

export default styled(Password)``;
