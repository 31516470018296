import React from 'react';
import PropTypes from 'prop-types';

import styleCustomerStatCard from './utils/style-customer-stat-card';

import { Card } from '../card';

const UnStyledCustomerStatCard = ({ className, typeOfCustomer, value, actionView }) => {
    return (
        <Card className={className} customer>
            <main>
                <div>
                    <span>{value}</span>
                    <div>
                        <span>Comptes clients</span>
                        <span>{typeOfCustomer}</span>
                    </div>
                </div>
                <section>{actionView && <section>{actionView}</section>}</section>
            </main>
        </Card>
    );
};

const StyledCustomerStatCard = styleCustomerStatCard(UnStyledCustomerStatCard);

export const CustomerStatCard = ({ ...restProps }) => {
    return <StyledCustomerStatCard {...restProps} />;
};

CustomerStatCard.propTypes = {
    typeOfCustomer: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    actionView: PropTypes.node
};
