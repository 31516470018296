import React, { useState, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CARD_TABS_TEST_ID } from '../utils/tests-ids';

import { Card } from './card';

// card tabs item
const UnStyledCardTab = ({ className, children, activeKey, tabKey }) => {
    return <div className={className}>{activeKey === tabKey && children}</div>;
};

const StyledCardTab = styled(UnStyledCardTab)`
    display: ${(props) => (props.activeKey === props.tabKey ? 'block' : 'none')};
`;

const CardTab = (props) => <StyledCardTab {...props} />;

CardTab.propTypes = {
    title: PropTypes.string.isRequired,
    tabKey: PropTypes.string.isRequired,
    activeKey: PropTypes.string
};

// card tabs
const UnStyledCardTabs = ({ className, testId = CARD_TABS_TEST_ID, children, defaultTab }) => {
    const tabs = React.Children.toArray(children).map((child) => ({
        title: child.props.title,
        tabKey: child.props.tabKey
    }));
    if (tabs.length === 0) throw new Error('You need to provide at least one element');

    const [activeKey, setActiveKey] = useState(defaultTab || tabs[0].tabKey);

    return (
        <Card>
            <div data-testid={testId} className={className}>
                <header>
                    <nav>
                        <ul>
                            {tabs.map((tab) => (
                                <li key={tab.tabKey} className={activeKey === tab.tabKey ? 'active' : ''} onClick={() => setActiveKey(tab.tabKey)}>
                                    {tab.title}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </header>
                <main>{React.Children.map(children, (child) => isValidElement(child) && cloneElement(child, { activeKey }))}</main>
            </div>
        </Card>
    );
};

const StyledCardTabs = styled(UnStyledCardTabs)`
    > header {
        > nav {
            position: relative;

            ::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                background: #d6dce9;
            }

            > ul {
                list-style: none;
                display: grid;
                grid-template-columns: ${(props) => `repeat(${React.Children.toArray(props.children).length}, 1fr)`};
                margin: 0;

                > li {
                    padding: 1rem;
                    position: relative;
                    color: var(--color-primary);
                    background: rgba(229, 232, 243, 0.64);
                    font-size: 0.84rem;
                    cursor: pointer;
                    transition: all 50ms ease;
                    text-align: center;

                    :first-child {
                        border-top-left-radius: 5px;
                    }

                    ::last-child {
                        border-top-right-radius: 5px;
                    }

                    :hover {
                        background: #f7f8fb;
                    }

                    &.active {
                        background: var(--color-white);
                        font-weight: 600;

                        :hover {
                            opacity: 1;
                        }

                        ::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            height: 2px;
                            background: var(--color-primary);
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }

    > main {
        padding: 1.5rem;
    }
`;

const CardTabs = (props) => <StyledCardTabs {...props} />;

CardTabs.propTypes = {
    testId: PropTypes.string,
    defaultTab: PropTypes.string
};

CardTabs.defautltProps = {
    defaultTab: null
};

CardTabs.Item = CardTab;

export default CardTabs;
