import styled from 'styled-components';

import { fontSizes } from '../../../config/sizes';

export default function (UnStyledStartCardTwo) {
    return styled(UnStyledStartCardTwo)`
        justify-content: center;
        align-items: center;
        min-height: 196px;

        > span {
            display: inline-block;

            :nth-child(1) {
                font-size: ${fontSizes['3xl']};
                font-weight: 600;
                margin-bottom: 1.5rem;
            }

            :nth-child(2) {
                font-size: 15px;
                font-weight: 600;
                text-align: center;
            }
        }

        > section {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 1.5rem;
        }
    `;
}
