import React from 'react';
import PropTypes from 'prop-types';

import styleButton from './utils/style-button';
import { getIconClassName, canShowActionIcon, getDisabled, isParentComponentTooltip } from './utils';
import { joinClassNames } from '../../utils/array';
import classes from '../../config/classes';
import styles from '../../styles.module.css';

import { ActionIcon } from './ActionIcon';

const UnStyledButton = ({ className, icon, iconStyle, actionIcon, size, disabled, parentComponent, children, onClick, type = 'submit' }) => {
    function handleClick(evt) {
        if (onClick) {
            if (isParentComponentTooltip(parentComponent)) {
                if (!disabled) onClick(evt);
            } else {
                onClick(evt);
            }
        }
    }

    return (
        <button className={joinClassNames(styles[classes.button], classes.button, className)} disabled={getDisabled(disabled, parentComponent)} onClick={handleClick} type={type}>
            <main>
                {icon && <span className={getIconClassName(iconStyle)}>{formatIconName(icon)}</span>}
                {displayActionIcon(actionIcon, size)}
                {children}
            </main>
        </button>
    );
};

function displayActionIcon(actionIcon, size) {
    return canShowActionIcon(actionIcon, size) && <ActionIcon name={actionIcon} />;
}

function formatIconName(icon) {
    const iconNamesMapping = {
        export: 'file_download',
        upload: 'file_upload'
    };

    return iconNamesMapping[icon] || icon;
}

const StyledButton = styleButton(UnStyledButton);

export const Button = ({ variant = 'filled', color = 'primary', size = 'normal', lightMode = 'none', disabled = false, fluid = false, iconStyle = 'filled', iconSize = 'sm', ...restProps }) => {
    return <StyledButton variant={variant} color={color} size={size} lightMode={lightMode} disabled={disabled} fluid={fluid} iconStyle={iconStyle} iconSize={iconSize} {...restProps} />;
};
Button.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['filled', 'outline']),
    color: PropTypes.oneOf(['primary', 'secondary', 'light', 'red']),
    lightMode: PropTypes.oneOf(['0', '1', '2', '3', 'none']),
    size: PropTypes.oneOf(['normal', 'medium', 'large']),
    disabled: PropTypes.bool,
    fluid: PropTypes.bool,
    icon: PropTypes.oneOf(['add', 'visibility', 'close', 'delete', 'add_box', 'save', 'delete_forever', 'send', 'print', 'refresh', 'export', 'edit', 'upload', 'check']),
    iconStyle: PropTypes.oneOf(['filled', 'outline']),
    iconSize: PropTypes.oneOf(['sm', 'md', 'lg']),
    actionIcon: PropTypes.oneOf(['delete', 'preview', 'print', 'save', 'send']),
    parentComponent: PropTypes.oneOf(['tooltip']),
    onClick: PropTypes.func
};
