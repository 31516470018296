import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckIcon from '../../assets/img/picto_check_circle.svg';
import ErrorIcon from '../../assets/img/picto_error.svg';
import ScheduleIcon from '../../assets/img/schedule_yellow.svg';

import enums from '../../utils/enums';

import Text from '../Text';
import TextBold from '../TextBold';
import TextRow from '../TextRow';
import Body from './Body';
import List from '../List';

const statusIcons = {
    CONFIRMED: CheckIcon,
    REJECTED: ErrorIcon,
    EXPIRED: ErrorIcon,
    CANCELLED_FROM_BACK_OFFICE: ErrorIcon,
    PENDING: ScheduleIcon
};

const UnStyledItemRGECertificateHeader = ({ className, rgeCertificate }) => {
    return (
        <div className={className}>
            {Object.keys(statusIcons).includes(rgeCertificate.status) && <img src={statusIcons[rgeCertificate.status]} alt='' />}
            <TextBold>{rgeCertificate.remoteName}</TextBold>
        </div>
    );
};

const StyledItemRGECertificateHeader = styled(UnStyledItemRGECertificateHeader)`
    display: flex;
    align-items: center;

    > img {
        margin-right: 8px;
        height: 16px;
    }
`;

const ItemRGECertificateHeader = (props) => <StyledItemRGECertificateHeader {...props} />;

ItemRGECertificateHeader.propTypes = {
    rgeCertificate: PropTypes.shape({})
};

ItemRGECertificateHeader.defaultProps = {
    rgeCertificate: {}
};

function UnStyledItemRGECertificateBody({ className, rgeCertificate }) {
    return (
        <div className={className}>
            {(rgeCertificate.reference || rgeCertificate.startDate || rgeCertificate.endDate) && (
                <Body>
                    <TextRow>
                        <Text.Label>Statut : </Text.Label>
                        <TextBold>{getRGECertificateStatus(rgeCertificate)}</TextBold>
                    </TextRow>
                    {rgeCertificate.reference && (
                        <TextRow>
                            <Text.Label>Numéro RGE : </Text.Label>
                            <TextBold>{rgeCertificate.reference}</TextBold>
                        </TextRow>
                    )}
                    <section>
                        {rgeCertificate.startDate && (
                            <TextRow>
                                <Text.Label>Date d'attribution : </Text.Label>
                                <Text>{new Date(rgeCertificate.startDate).toLocaleDateString()}</Text>
                            </TextRow>
                        )}
                        {rgeCertificate.endDate && (
                            <TextRow>
                                <Text.Label>Date de fin de validité : </Text.Label>
                                <Text>{new Date(rgeCertificate.endDate).toLocaleDateString()}</Text>
                            </TextRow>
                        )}
                    </section>
                    {rgeCertificate.domains && (
                        <section>
                            <Text.Label>Liste des domaines : </Text.Label>
                            <List items={rgeCertificate.domains} />
                        </section>
                    )}
                </Body>
            )}
        </div>
    );
}

function getRGECertificateStatus(rgeCertificate) {
    let status = enums.organizationDocumentStatusTranslations[rgeCertificate.status];

    if (status === enums.organizationDocumentStatusTranslations.EXPIRED) {
        status = status.concat(` le ${new Date(rgeCertificate.endDate).toLocaleDateString()}`);
    } else if (status === enums.organizationDocumentStatusTranslations.CANCELLED_FROM_BACK_OFFICE) {
        status = status.concat(` le ${new Date(rgeCertificate.backOfficeCancellation.cancelledAt).toLocaleDateString()}`);
    }

    return status;
}

const StyledItemRGECertificateBody = styled(UnStyledItemRGECertificateBody)`
    > ${Body} {
        > section {
            display: grid;
            grid-auto-flow: column;
            gap: 1rem;

            :last-child {
                display: flex;
                flex-direction: column;
                text-align: start;
                gap: 0.65rem;
            }
        }
    }
`;

const ItemRGECertificateBody = (props) => <StyledItemRGECertificateBody {...props} />;

ItemRGECertificateBody.propTypes = {
    rgeCertificate: PropTypes.shape({})
};

ItemRGECertificateBody.defaultProps = {
    rgeCertificate: {}
};

const ItemRGECertificate = {
    Header: ItemRGECertificateHeader,
    Body: ItemRGECertificateBody
};

export default ItemRGECertificate;
