import React, { isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';

import styleMenu from './utils/style-menu';

import { MenuPopover } from './MenuPopover';
import useMenu from './hooks/useMenu';

const UnStyledMenu = ({ className, trigger, size, children }) => {
    const { visibility, handleTriggerClick } = useMenu();

    return (
        <div className={className}>
            {displayTrigger(trigger, handleTriggerClick)}
            {children && (
                <MenuPopover className={visibility} size={size}>
                    {children}
                </MenuPopover>
            )}
        </div>
    );
};

function displayTrigger(trigger, handleTriggerClick) {
    return isValidElement(trigger) && cloneElement(trigger, { onClick: handleTriggerClick });
}

const StyledMenu = styleMenu(UnStyledMenu);

export const Menu = ({ size = 'md', ...restProps }) => <StyledMenu size={size} {...restProps} />;
Menu.propTypes = {
    trigger: PropTypes.node.isRequired,
    size: PropTypes.oneOf(['sm', 'md', 'lg'])
};
