import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../config/colors';

function getFontWeight({ black, textStyle, fontWeight }) {
    if (fontWeight) return fontWeight;
    if (textStyle && textStyle === 'black') return 700;
    else if (black) return 600;

    return 500;
}

const UnStyledTextBold = ({ className, style, children }) => {
    return (
        <span className={className} style={style}>
            {children}
        </span>
    );
};

const StyledTextBold = styled(UnStyledTextBold)`
    font-weight: ${(props) => getFontWeight(props)};
    color: ${(props) => colors[props.color]};
    font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
    font-size: ${(props) => (props.asTitle ? '1.5rem' : props.asSubTitle ? '13px' : '12px')};
`;

const TextBold = ({ style = {}, ...restOfProps }) => <StyledTextBold style={style} {...restOfProps} />;

TextBold.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'black', '']),
    italic: PropTypes.bool,
    asTitle: PropTypes.bool,
    asSubTitle: PropTypes.bool,
    className: PropTypes.string,
    black: PropTypes.bool,
    style: PropTypes.shape({}),
    fontWeight: PropTypes.oneOf([400, 500, 600, 700])
};

TextBold.defaultProps = {
    color: 'black',
    italic: false,
    asTitle: false,
    asSubTitle: false,
    className: '',
    black: false
};

const TextBoldLabel = styled.span`
    font-weight: 500;
    color: #74798c;
    font-size: ${(props) => (props.asSubTitle ? '14px' : '12px')};
`;

TextBold.Label = TextBoldLabel;

export default TextBold;
