import styled from 'styled-components';

import colors from '../../../config/colors';
import dimensions from '../../../config/dimensions';
import { getCursor } from '../../../utils/button';

export default function (UnStyledActionButton) {
    return styled(UnStyledActionButton)`
        width: ${(props) => getWidth(props)};
        height: ${(props) => getHeight(props)};
        border: 1px solid ${(props) => getBorderColor(props)};
        background: ${(props) => getBackgroundColor(props)};
        color: ${(props) => getColor(props)};
        border-radius: ${(props) => getBorderRadius(props)};
        cursor: ${(props) => getCursor(props)};

        > main {
            > span {
                &.material-icons,
                &.material-icons-outlined {
                    font-size: 20px;
                }
            }
        }

        :hover {
            background: ${(props) => getBackgroundColor(props, true)};
        }
    `;
}

function getWidth({ size }) {
    return dimensions.actionButton.width[size];
}

function getHeight({ size }) {
    return dimensions.actionButton.height[size];
}

function getBackgroundColor({ colorScheme, disabled }, hovered = false) {
    return disabled ? 'transparent' : hovered ? colors[`${colorScheme}OutlineLight`] : 'transparent';
}

function getBorderRadius({ variant }) {
    return dimensions.button.borderRadius[variant];
}

function getBorderColor(props) {
    return getColor(props);
}

function getColor({ colorScheme, disabled }) {
    return disabled ? colors.disabled : colors[colorScheme];
}
