import add from '../assets/img/buttons/add.svg';
import save from '../assets/img/buttons/save.svg';
import send from '../assets/img/buttons/send.svg';
import addQuotation from '../assets/img/buttons/add_quotation.svg';
import printSecondary from '../assets/img/buttons/print_secondary.svg';
import previewSecondary from '../assets/img/buttons/preview_secondary.svg';
import addSubcontractor from '../assets/img/buttons/add_subcontractors.svg';
import addContract from '../assets/img/buttons/add_contract.svg';
import trash from '../assets/img/buttons/delete.svg';
import trashFilled from '../assets/img/buttons/delete_filled.svg';
import sendDossierCEE from '../assets/img/buttons/send_dossier_cee.svg';
import expandMore from '../assets/img/expand_more.svg';
import pictoArrow from '../assets/img/picto_arrow.svg';

import customer from '../assets/img/cards/customer.svg';
import customerColored from '../assets/img/cards/customer_colored.svg';
import customerColoredSmall from '../assets/img/cards/customer_colored_small.svg';
import cardSecondary from '../assets/img/cards/card_secondary.svg';
import cee from '../assets/img/cards/cee.svg';
import customerGroup from '../assets/img/cards/customer_group.svg';
import building from '../assets/img/cards/building.svg';
import buildingWhite from '../assets/img/cards/building_white.svg';

import quotationSentPrimary from '../assets/img/cards/quotation_sent_primary.svg';
import quotationApprovedPrimary from '../assets/img/cards/quotation_approved_primary.svg';
import billSecondary from '../assets/img/cards/bill_secondary.svg';

import renolibPrimary from '../assets/img/others/renolib_primary.svg';
import renolibWhite from '../assets/img/others/renolib-white.svg';

import blockHomeAddress from '../assets/img/cards/blocs/picto_adresses.svg';
import blockWorkAddress from '../assets/img/cards/blocs/picto_adresses_travaux.svg';
import blockCertificate from '../assets/img/cards/blocs/picto_attestations.svg';
import blockOrganization from '../assets/img/cards/blocs/picto_organisation.svg';
import blockRenolib from '../assets/img/cards/blocs/picto_rge.svg';
import blockBill from '../assets/img/cards/blocs/picto_facture.svg';
import blockUserGroup from '../assets/img/cards/blocs/picto_soustraitant.svg';
import blockSecurity from '../assets/img/cards/blocs/picto_security.svg';
import blockHome from '../assets/img/cards/blocs/picto_secteur.svg';
import blockUser from '../assets/img/cards/blocs/picto_fiche.svg';
import blockFolder from '../assets/img/cards/blocs/picto_doc_joint.svg';
import blockDepartment from '../assets/img/cards/blocs/picto_departement.svg';
import blockContract from '../assets/img/cards/blocs/picto_contrat_oblige.svg';
import blockGroup from '../assets/img/cards/blocs/group.svg';
import blockPlace from '../assets/img/cards/blocs/place.svg';
import blockAccount from '../assets/img/cards/blocs/folder_shared.svg';
import blockPerson from '../assets/img/cards/blocs/person.svg';
import blockLock from '../assets/img/cards/blocs/lock.svg';
import blockLight from '../assets/img/cards/blocs/operation_volume.svg';
import blockComment from '../assets/img/cards/blocs/comment.svg';

import smallBillSecondary from '../assets/img/cards/picto_bill_small.svg';
import smallCardPrimary from '../assets/img/cards/picto_card_small.svg';

import closeIcon from '../assets/img/close.svg';
import closeIconPrimary from '../assets/img/close_primary.svg';
import fileDownloadPrimary from '../assets/img/file_download_blue.svg';
import fileDownloadWhite from '../assets/img/file_download_white.svg';
import pictoSearch from '../assets/img/picto_search.svg';

const Icons = {
    add,
    save,
    send,
    addQuotation,
    printSecondary,
    previewSecondary,
    addSubcontractor,
    addContract,
    customer,
    cardSecondary,
    renolibPrimary,
    renolibWhite,
    cee,
    customerGroup,
    building,
    buildingWhite,
    customerColored,
    customerColoredSmall,
    quotationSentPrimary,
    quotationApprovedPrimary,
    billSecondary,
    expandMore,
    pictoArrow,

    blockHomeAddress,
    blockWorkAddress,
    blockCertificate,
    blockOrganization,
    blockRenolib,
    blockBill,
    blockUserGroup,
    blockSecurity,
    blockHome,
    blockUser,
    blockFolder,
    blockDepartment,
    blockContract,
    blockGroup,
    blockPlace,
    blockAccount,
    blockPerson,
    blockLock,
    blockLight,
    blockComment,

    smallBillSecondary,
    smallCardPrimary,

    trash,
    trashFilled,
    sendDossierCEE,

    closeIcon,
    closeIconPrimary,
    fileDownloadPrimary,
    fileDownloadWhite,
    pictoSearch
};

export default Icons;
