import styled from 'styled-components';

import colors from '../../../config/colors';
import { fontSizes } from '../../../config/sizes';

export default function (UnStyledStatSelect) {
    return styled(UnStyledStatSelect)`
        > button {
            display: inline-block;
            border: ${getBorder()};
            border-radius: 1.5rem;
            padding: 0.5rem 1rem;
            font-size: ${fontSizes.sm};
            color: ${colors.input.label};
            font-weight: ${getFontWeight()};
            background: ${getBackgroundColor()};
            margin-right: 2rem;
            transition: all 200ms ease-in-out;
            cursor: pointer;

            :last-child {
                margin-right: 0;
            }

            :hover {
                border: ${getBorder(true)};
            }

            &.selected {
                font-weight: ${getFontWeight(true)};
                background: ${getBackgroundColor(true)};
            }
        }
    `;
}

function getBorder(hovered = false) {
    return hovered ? `1px solid ${colors.input.label}` : '1px solid transparent';
}

function getFontWeight(selected = false) {
    return selected ? 600 : 400;
}

function getBackgroundColor(selected = false) {
    return selected ? '#f5f6fa' : 'transparent';
}
