import React from 'react';

import styleFieldError from './utils/style-field-error';

function UnStyledFieldError({ className, children, style = {} }) {
    return (
        <span className={className} style={style} role='alert' aria-label={getAriaLabel(children)}>
            {children}
        </span>
    );
}

function getAriaLabel(children) {
    return typeof children === 'string' ? children : '';
}

const StyledFieldError = styleFieldError(UnStyledFieldError);

export function FieldError(props) {
    return <StyledFieldError {...props} />;
}
