import React, { useState, cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ExpandableListItem } from './ExpandableListItem';

function UnStyledExpandableList({ testId, className, items, editable }) {
    const [selectedIndex, setSelectedIndex] = useState(-1);

    return (
        items.length > 0 && (
            <ul data-testid={testId} className={className}>
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        {isValidElement(item) &&
                            cloneElement(item, {
                                onClick: setSelectedIndex,
                                index,
                                selectedIndex,
                                editable,
                                itemsCount: items.length
                            })}
                    </React.Fragment>
                ))}
            </ul>
        )
    );
}

const StyledExpandabledList = styled(UnStyledExpandableList)`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export function ExpandableList({ editable = true, ...restProps }) {
    return <StyledExpandabledList editable={editable} {...restProps} />;
}
ExpandableList.propTypes = {
    testId: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.node).isRequired,
    editable: PropTypes.bool,
    viewable: PropTypes.bool
};
ExpandableList.Item = ExpandableListItem;
