import styled from 'styled-components';

import dimensions from '../../../config/dimensions';
import zIndices from '../../../config/z-indices';
import colors from '../../../config/colors';

const { arrowBorderWidth } = dimensions.tooltip;

export default function (UnStyledTooltip) {
    return styled(UnStyledTooltip)`
        display: ${(props) => getDisplay(props)};
        position: relative;

        > div {
            :first-child {
                position: absolute;
                width: ${(props) => getWidth(props)};
                top: ${(props) => getContainerTop(props)};
                right: ${(props) => getContainerRight(props)};
                bottom: ${(props) => getContainerBottom(props)};
                left: ${(props) => getContainerLeft(props)};
                background: transparent;
                z-index: ${zIndices.tooltip};
                opacity: 0;
                transform: scale(0);
                transform-origin: ${(props) => getTransformOrigin(props)};
                transition: all 250ms ease-in-out;

                > div {
                    position: relative;
                    max-width: 100%;
                    filter: drop-shadow(0 0 4px rgba(144, 177, 225, 0.38));

                    ::before {
                        content: attr(data-content);
                        white-space: pre-line;
                        position: absolute;
                        top: ${(props) => getBubbleTop(props)};
                        right: ${(props) => getBubbleRight(props)};
                        bottom: ${(props) => getBubbleBottom(props)};
                        left: ${(props) => getBubbleLeft(props)};
                        background: ${(props) => getBubbleBackgroundColor(props)};
                        color: ${(props) => getBubbleTextColor(props)};
                        border-radius: 5px;
                        padding: 0.75rem 1rem;
                        margin: ${(props) => getBubbleMargin(props)};
                        transform: ${(props) => getBubbleTransform(props)};
                    }

                    ::after {
                        content: '';
                        position: absolute;
                        top: ${(props) => getBubbleTop(props)};
                        right: ${(props) => getBubbleRight(props)};
                        bottom: ${(props) => getBubbleBottom(props)};
                        left: ${(props) => getBubbleLeft(props)};
                        border-top: ${(props) => getArrowBorderTop(props)};
                        border-right: ${(props) => getArrowBorderRight(props)};
                        border-bottom: ${(props) => getArrowBorderBottom(props)};
                        border-left: ${(props) => getArrowBorderLeft(props)};
                        margin: ${(props) => getArrowMargin(props)};
                        transform: ${(props) => getBubbleTransform(props)};
                    }
                }
            }
        }

        &.shown {
            > div {
                :first-child {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }

        &.hidden {
            > div {
                :first-child {
                    transform: scale(0);
                    opacity: 0;
                }
            }
        }
    `;
}

function getDisplay({ fluid }) {
    return fluid ? 'block' : 'inline-block';
}

function getWidth({ size }) {
    return dimensions.tooltip.width[size];
}

function getTransformOrigin({ placement }) {
    return { top: '0 50%', right: '0 50%', bottom: '0 50%', left: '100% 50%' }[placement];
}

function getContainerTop({ placement }) {
    return { top: 'initial', right: '50%', bottom: '100%', left: '50%' }[placement];
}

function getContainerRight({ placement }) {
    return { top: 'initial', right: 'initial', bottom: 'initial', left: '100%' }[placement];
}

function getContainerBottom({ placement }) {
    return { top: '100%', right: 'initial', bottom: 'initial', left: 'initial' }[placement];
}

function getContainerLeft({ placement }) {
    return { top: '50%', right: '100%', bottom: '50%', left: 'initial' }[placement];
}

function getBubbleBackgroundColor() {
    return colors.white;
}

function getBubbleTextColor({ type }) {
    return { info: colors.black, warning: colors.warning, error: colors.error }[type];
}

function getBubbleTop({ placement }) {
    return { top: 'initial', right: 'initial', bottom: '0', left: 'initial' }[placement];
}

function getBubbleRight({ placement }) {
    return { top: 'initial', right: 'initial', bottom: 'initial', left: '0' }[placement];
}

function getBubbleBottom({ placement }) {
    return { top: '0', right: 'initial', bottom: 'initial', left: 'initial' }[placement];
}

function getBubbleLeft({ placement }) {
    return { top: 'initial', right: '0', bottom: 'initial', left: 'initial' }[placement];
}

function getBubbleTransform({ placement }) {
    return ['left', 'right'].includes(placement) ? 'translateY(-50%)' : 'translateX(-50%)';
}

function getBubbleMargin({ placement }) {
    return { top: '0 0 1rem 0', right: '0 0 0 1rem', bottom: '1rem 0 0 0', left: '0 1rem 0 0' }[placement];
}

function getArrowBorderTop({ placement, type }) {
    return {
        top: `${arrowBorderWidth} solid ${getBubbleBackgroundColor({ type })}`,
        right: `${arrowBorderWidth} solid transparent`,
        bottom: 'none',
        left: `${arrowBorderWidth} solid transparent`
    }[placement];
}

function getArrowBorderRight({ placement, type }) {
    return {
        top: `${arrowBorderWidth} solid transparent`,
        right: `${arrowBorderWidth} solid ${getBubbleBackgroundColor({ type })}`,
        bottom: `${arrowBorderWidth} solid transparent`,
        left: 'initial'
    }[placement];
}

function getArrowBorderBottom({ placement, type }) {
    return {
        top: 'none',
        right: `${arrowBorderWidth} solid transparent`,
        bottom: `${arrowBorderWidth} solid ${getBubbleBackgroundColor({ type })}`,
        left: `${arrowBorderWidth} solid transparent`
    }[placement];
}

function getArrowBorderLeft({ placement, type }) {
    return {
        top: `${arrowBorderWidth} solid transparent`,
        right: 'none',
        bottom: `${arrowBorderWidth} solid transparent`,
        left: `${arrowBorderWidth} solid ${getBubbleBackgroundColor({ type })}`
    }[placement];
}

function getArrowMargin({ placement }) {
    return { top: '0 0 0.4rem 0', right: '0 0 0 0.4rem', bottom: '0.4rem 0 0 0', left: '0 0.4rem 0 0' }[placement];
}
