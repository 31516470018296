export default {
    MALE: {
        SHORT: 'M.',
        LONG: 'Monsieur'
    },
    FEMALE: {
        SHORT: 'Mme',
        LONG: 'Madame'
    }
};
