import React from 'react';
import styled from 'styled-components';
import useImageSlider from './hooks/useImageSlider';
import Pagination from '../Pagination';
import { zoomIn, fadeIn } from '../../config/animations';
import PropTypes from 'prop-types';

export const UnStyledSlider = ({ className, slides, onSlideChange, renderSlide }) => {
    const { activeSlideIndex, hasPageChangedOnce, handleSlideChange } = useImageSlider(slides, onSlideChange);

    return (
        <div className={className}>
            <div>
                {slides.map((slide, slideIndex) => (
                    <div key={slideIndex} className={getClassName(slideIndex, activeSlideIndex, hasPageChangedOnce)}>
                        {renderSlide && renderSlide({ fileDownloadUrl: slide })}
                    </div>
                ))}
            </div>
            <footer>{slides.length > 1 && <Pagination pagesCount={slides.length} onPageChange={handleSlideChange} />}</footer>
        </div>
    );
};

UnStyledSlider.propTypes = {
    className: PropTypes.string,
    slides: PropTypes.arrayOf(PropTypes.string),
    onSlideChange: PropTypes.func,
    renderSlide: PropTypes.func
};

export const StyledSlider = styled(UnStyledSlider)`
    width: 100%;
    height: 60vh;

    footer {
        margin-top: 1rem;
    }
    > div {
        width: 100%;
        height: 60vh;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: initial;
        left: initial;
        top: initial;
        bottom: initial;

        > div {
            &.hidden {
                display: none;
            }

            &.default {
                transform: scale(1);
                opacity: 1;
                width: 100%;
                height: 60vh;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                position: initial;
                left: initial;
                top: initial;
                bottom: initial;
            }

            &.shown {
                animation: ${zoomIn} 300ms linear forwards, ${fadeIn} 500ms linear forwards;
                transform: scale(1);
                opacity: 1;
                width: 100%;
                height: 60vh;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                position: initial;
                left: initial;
                top: initial;
                bottom: initial;
            }
        }
    }
`;

function getClassName(slideIndex, activeSlideIndex, hasPageChangedOnce) {
    return slideIndex === activeSlideIndex ? (hasPageChangedOnce ? 'shown' : 'default') : 'hidden';
}
