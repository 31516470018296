import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import TextBold from '../TextBold';
import Text from '../Text';

import Body from './Body';

const UnStyledItemAddressHeader = ({ className, address }) => {
    return (
        <div className={className}>
            <TextBold>{address.isMainAddress ? 'Siège social' : 'Etablissement'}</TextBold>
            <Text>
                {address.streetNumber ? `${address.streetNumber},` : ''} {address.streetName}
            </Text>
            <Text>{address.postalCode}</Text>
            <Text>{address.city}</Text>
        </div>
    );
};

const StyledItemAddressHeader = styled(UnStyledItemAddressHeader)`
    display: grid;
    grid-template-columns: 2fr 3fr 1.2fr 1.2fr;
    gap: 0.5rem;
`;

const ItemAddressHeader = (props) => <StyledItemAddressHeader {...props} />;

ItemAddressHeader.propTypes = {
    address: PropTypes.shape({})
};
ItemAddressHeader.defaultProps = {
    address: {}
};

const ItemAddressBody = ({ address }) => {
    return (
        <React.Fragment>
            {address.addressName && (
                <Body>
                    <Text.Label>Nom : </Text.Label>
                    <Text>{address.addressName}</Text>
                </Body>
            )}
        </React.Fragment>
    );
};

ItemAddressBody.propTypes = {
    address: PropTypes.shape({})
};
ItemAddressBody.defaultProps = {
    address: {}
};

const ItemAddress = { Header: ItemAddressHeader, Body: ItemAddressBody };

export default ItemAddress;
