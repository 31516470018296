export const TOOLTIP_CONTENT_TEST_ID = 'tooltip-content';

export const POPOVER_CONTENT_TEST_ID = 'popover-content';

export const SELECT_TEST_ID = 'select';

export const MAIN_TABS_TEST_ID = 'main-tabs';

export const IMAGE_SLIDER_TEST_ID = 'image-slider';

export const PAGINATION_TEST_ID = 'pagination';

export const CARD_TABS_TEST_ID = 'cards-tabs';

export const UPLOAD_INPUT_TEST_ID = 'file-upload';
