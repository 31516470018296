import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import TextBold from '../TextBold';
import Text from '../Text';

import Body from './Body';
import utils from '../../utils';

const UnStyledItemAdvancePaymentHeader = ({ className, advancePayment, quotation, index }) => {
    return (
        <div className={className}>
            <TextBold>Acompte n°{index + 1}</TextBold>

            <TextBold>{utils.formatPrice(calculateAmountOfAdvancePayment({ quotation, advancePayment }))}</TextBold>
        </div>
    );
};

function calculateAmountOfAdvancePayment({ quotation, advancePayment }) {
    if (advancePayment.type === 'PERCENTAGE') return (advancePayment.amount * quotation.totalNetPriceToPay) / 100;

    return advancePayment.amount;
}

const StyledItemAdvancePaymentHeader = styled(UnStyledItemAdvancePaymentHeader)`
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
    gap: 0.5rem;
`;

const ItemAdvancePaymentHeader = (props) => <StyledItemAdvancePaymentHeader {...props} />;

ItemAdvancePaymentHeader.propTypes = {
    advancePayment: PropTypes.shape({})
};
ItemAdvancePaymentHeader.defaultProps = {
    advancePayment: {}
};

const ItemAdvancePaymentBody = ({ advancePayment }) => {
    return (
        <React.Fragment>
            {advancePayment.comment && (
                <Body>
                    <Text.Label>Commentaire : </Text.Label>
                    <Text>{advancePayment.comment}</Text>
                </Body>
            )}
        </React.Fragment>
    );
};

ItemAdvancePaymentBody.propTypes = {
    advancePayment: PropTypes.shape({})
};
ItemAdvancePaymentBody.defaultProps = {
    advancePayment: {}
};

const ItemAdvancePayment = { Header: ItemAdvancePaymentHeader, Body: ItemAdvancePaymentBody };

export default ItemAdvancePayment;
