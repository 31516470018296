import React from 'react';

import PropTypes from 'prop-types';

import styled from 'styled-components';

import colors from '../config/colors';

const UnStyledText = ({ className, children }) => {
    return <span className={className}>{children}</span>;
};

const StyledText = styled(UnStyledText)`
    color: ${(props) => colors[props.color]};
`;

const Text = (props) => <StyledText {...props} />;

Text.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'black', '']),
    className: PropTypes.string
};

Text.defaultProps = {
    color: 'black',
    className: ''
};

// text bold label
const TextLabel = styled.span`
    color: #74798c;
`;

Text.Label = TextLabel;

export default Text;
