import { isFirefoxBrowser } from '../../../utils';

function canShowLabel(type, label) {
    return !['radio', 'checkbox'].includes(type) && !!label;
}

function getAutoComplete(autoCompleted) {
    return autoCompleted ? '' : isFirefoxBrowser() ? 'off' : 'none';
}

export { canShowLabel, getAutoComplete };
