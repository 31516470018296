import React from 'react';
import PropTypes from 'prop-types';

import { Field } from '../../input';

function SocialLandlordBonification({ renovationAddress, disabled, onConventionalHousingChange }) {
    const { uuid, socialLandlordBonification } = renovationAddress;

    return (
        <Field
            fluid
            type='checkbox'
            disabled={disabled}
            name={`conventionalHousing-${uuid}`}
            label='Logement conventionné'
            defaultChecked={socialLandlordBonification.conventionalHousing}
            onChange={onConventionalHousingChange}
            className='mb-3'
        />
    );
}

SocialLandlordBonification.propTypes = {
    renovationAddress: PropTypes.shape({
        socialLandlordBonification: PropTypes.shape({
            conventionalHousing: PropTypes.bool
        })
    }).isRequired,
    onConventionalHousingChange: PropTypes.func.isRequired
};

export default SocialLandlordBonification;
