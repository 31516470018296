import React from 'react';
import PropTypes from 'prop-types';

import styleStatCardOne from './utils/style-stat-card-one';

import { StatSelect } from './StatSelect';

const UnStyledStatCardOne = ({ className, title, value, options, actionView, onSelectOption }) => {
    return (
        <main className={className}>
            <span>{title}</span>
            {displayStatSelect(options, onSelectOption)}
            <section>
                <span>{value}</span>
                {actionView && <section>{actionView}</section>}
            </section>
        </main>
    );
};

function displayStatSelect(options, onSelectOption) {
    return options.length > 0 && <StatSelect options={options} onSelectOption={onSelectOption} />;
}

const StyledStatCardOne = styleStatCardOne(UnStyledStatCardOne);

export const StatCardOne = (props) => <StyledStatCardOne {...props} />;
StatCardOne.propTypes = {
    color: PropTypes.string,
    lightMode: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    actionView: PropTypes.node,
    onSelectOption: PropTypes.func
};
