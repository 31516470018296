import React from 'react';
import PropTypes from 'prop-types';

import styleMenuItem from './utils/style-menu-item';

const UnStyledMenuItem = ({ className, disabled, children, onClick }) => {
    function handleClick() {
        if (onClick && !disabled) onClick();
    }

    return (
        <div role='menuitem' className={className} onClick={handleClick}>
            {children}
        </div>
    );
};

const StyledMenuItem = styleMenuItem(UnStyledMenuItem);

export const MenuItem = ({ disabled = false, ...restProps }) => <StyledMenuItem disabled={disabled} {...restProps} />;
MenuItem.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};
