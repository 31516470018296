import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Occupant } from './Occupant';

const HouseholdIncomeBonification = ({ className, renovationAddress, editable = true, onEditOccupant, onDeleteOccupant }) => {
    const { householdIncomeBonification } = renovationAddress;

    return (
        <div className={className}>
            {householdIncomeBonification.housings.map((housing, index) => (
                <React.Fragment key={`housing-${index}`}>
                    {housing.occupants.map((occupant, index) => {
                        return (
                            <Occupant
                                key={`occupant-${index}`}
                                occupant={occupant}
                                housing={housing}
                                editable={editable}
                                onEditOccupant={() => onEditOccupant && onEditOccupant(renovationAddress, housing, occupant)}
                                onDeleteOccupant={() => onDeleteOccupant && onDeleteOccupant(renovationAddress, housing, occupant)}
                            />
                        );
                    })}
                </React.Fragment>
            ))}
        </div>
    );
};

HouseholdIncomeBonification.propTypes = {
    renovationAddress: PropTypes.shape({
        homeAddress: PropTypes.bool,
        householdIncomeBonification: PropTypes.shape({
            housings: PropTypes.arrayOf(PropTypes.shape({}))
        })
    }),
    editable: PropTypes.bool
};

export default styled(HouseholdIncomeBonification)`
    > header {
        display: flex;
    }

    @media (max-width: 1600px) {
        > header {
            display: initial;

            > span {
                margin-bottom: 0.5rem;

                :last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    > main {
        margin-top: 0.75rem;
    }
`;
