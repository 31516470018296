import PropTypes from 'prop-types';

export const columnsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        dataIndex: PropTypes.string,
        sortable: PropTypes.bool,
        disabled: PropTypes.bool,
        render: PropTypes.func,
        format: PropTypes.func
    })
);

export const dataSourcePropType = PropTypes.arrayOf(PropTypes.shape({}));

export const actionsPropType = PropTypes.element;
