import styled from 'styled-components';

export default function (UnStyledTableFooter) {
    return styled(UnStyledTableFooter)`
        margin-top: 3rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        > span {
            position: absolute;
            left: 0;
        }
    `;
}
