import colors from '../config/colors';

function isValidLightMode(color, lightMode) {
    return ['primary', 'secondary'].includes(color) && lightMode && lightMode !== 'none';
}

function getColor(color, lightMode) {
    return isValidLightMode(color, lightMode) ? colors[`${color}Light${lightMode}`] : colors[color];
}

export { isValidLightMode, getColor };
