import styled from 'styled-components';

const TextGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 0.65rem;

    > * {
        :first-child {
            margin-bottom: 0.25rem;
        }
    }
`;

export default TextGroup;
