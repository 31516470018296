import React, { isValidElement, cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';

import styleImageSlider from './utils/style-image-slider';
import { isNonEmptyString, isNullish } from '../../utils';
import { IMAGE_SLIDER_TEST_ID } from '../../utils/tests-ids';
import { joinClassNames } from '../../utils/array';

import { Image } from '../image';
import Pagination from '../Pagination';
import useImageSlider from './hooks/useImageSlider';

function UnStyledImageSlider({ className, slides, noDataView, onSlideChange }) {
    const { activeSlideIndex, hasPageChangedOnce, handleSlideChange } = useImageSlider(slides, onSlideChange);

    useEffect(() => {
        const slides = Array.from(document.getElementsByClassName('slide'));
        const activeSlide = slides[activeSlideIndex];
        if (activeSlide) {
            const [image] = Array.from(activeSlide.getElementsByTagName('img'));
            const [comment] = Array.from(activeSlide.getElementsByTagName('span'));
            if (isNullish(comment) || isNullish(image)) return;

            const imageMaxHeight = Math.floor(((activeSlide.clientHeight - comment.offsetHeight) / activeSlide.clientHeight) * 100);
            if (imageMaxHeight > 60) {
                image.style.maxHeight = `${imageMaxHeight}%`;
            } else {
                activeSlide.style.overflowY = 'auto';
                image.style.marginTop = '50%';
            }
        }
    }, [activeSlideIndex]);

    return (
        <div data-testid={IMAGE_SLIDER_TEST_ID} className={className}>
            {slides.map((slide, slideIndex) => (
                <div key={slideIndex} className={joinClassNames('slide', getClassName(slideIndex, activeSlideIndex, hasPageChangedOnce))}>
                    {isNonEmptyString(slide.fileDownloadUrl) ? <Image src={slide.fileDownloadUrl} /> : isValidElement(noDataView) && cloneElement(noDataView, { slide })}
                    {isNonEmptyString(slide.fileDownloadUrl) && isNonEmptyString(slide.comment) && <span>{slide.comment}</span>}
                </div>
            ))}
            <footer>{slides.length > 1 && <Pagination pagesCount={slides.length} onPageChange={handleSlideChange} />}</footer>
        </div>
    );
}

function getClassName(slideIndex, activeSlideIndex, hasPageChangedOnce) {
    return slideIndex === activeSlideIndex ? (hasPageChangedOnce ? 'shown' : 'default') : 'hidden';
}

const StyledImageSlider = styleImageSlider(UnStyledImageSlider);

export function ImageSlider({ slides = [], ...restProps }) {
    return <StyledImageSlider slides={slides} {...restProps} />;
}
ImageSlider.propTypes = {
    slides: PropTypes.arrayOf(
        PropTypes.shape({
            fileDownloadUrl: PropTypes.string
        })
    ),
    noDataView: PropTypes.node,
    onSlideChange: PropTypes.func
};
