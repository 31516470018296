import styled from 'styled-components';

export const SwitchCheckboxWrapper = styled.div`
    input[type='checkbox'] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    // slider background
    label {
        margin-top: 7px;
        text-indent: -9999px;
        width: 35px;
        height: 15px;
        background: #808080;
        display: block;
        border-radius: 36px;
        position: relative;
        cursor: pointer;
    }

    // little white ball
    label:after {
        content: '';
        position: absolute;
        top: 1.2px;
        left: 2px;
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }

    input:checked + label {
        background: #1d3495;
    }

    input:checked + label:after {
        left: calc(95%);
        transform: translateX(-100%);
    }

    label:active:after {
        width: 20px;
    }
`;
