import React from 'react';
import PropTypes from 'prop-types';

function formatStyleObject({ mt, ...styles }) {
    const formattedStyles = styles;
    if (mt) Object.assign(formattedStyles, { marginTop: mt });

    return formattedStyles;
}

function Box({ className, children, ...props }) {
    const { display, ...styles } = props;

    return (
        <div className={className} style={{ display, ...formatStyleObject(styles) }}>
            {children}
        </div>
    );
}

Box.propTypes = {
    display: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    justifyContent: PropTypes.oneOf([
        'space-between',
        'space-evenly',
        'space-aroung',
        'start',
        'end',
        'center',
        'flex-start',
        'flex-end'
    ])
};

export default Box;
