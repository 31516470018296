import styled from 'styled-components';
import dimensions from '../../../config/dimensions';
import colors from '../../../config/colors';

export default function (UnStyledAutocomplete) {
    return styled(UnStyledAutocomplete)`
        ${getStyle}
    `;
}

function getStyle(props) {
    return props.searchIcon
        ? `
    position: relative;
    border-radius: ${dimensions.radius};
    :focus-within {
        border: 1px solid ${colors.primary};
        box-shadow: 0 0 0 1px rgba(178, 209, 255, 0.49);
    }

    > div {
        display: flex;
        align-items: center;
        > div {
            width: 90%;
        }
        > .icon {
            border-radius: 0 ${dimensions.radius} ${dimensions.radius} 0;
            background: #e5e8f3;
            min-height: 37.2px;
            width: 1.9rem;
            padding-top: 0.5rem;
            padding-left: 0.3rem;
            > img {
                height: 1.2rem;
                width: 1.2rem;
            }
        }
    }
`
        : `
position: relative;

`;
}
