import React from 'react';
import PropTypes from 'prop-types';

import styleHeaderCard from './utils/style-header-card';

import { Card } from '../card';

const UnStyledHeaderCard = ({ className, title, subTitle, label, actionView, style = {} }) => {
    return (
        <Card className={className} style={style}>
            <main>
                <div>
                    <span>{title}</span>
                    {label && <span>{label}</span>}
                </div>
                {subTitle && <span>{subTitle}</span>}
            </main>
            {actionView && <footer>{actionView}</footer>}
        </Card>
    );
};

const StyledHeaderCard = styleHeaderCard(UnStyledHeaderCard);

export const HeaderCard = ({
    variant = 'ghost',
    color = 'primary',
    lightMode = 'none',
    titleVariant = '1',
    labelVariant = '1',
    ...restProps
}) => {
    return (
        <StyledHeaderCard
            variant={variant}
            color={color}
            lightMode={lightMode}
            titleVariant={titleVariant}
            labelVariant={labelVariant}
            {...restProps}
        />
    );
};
HeaderCard.propTypes = {
    style: PropTypes.shape({}),
    variant: PropTypes.oneOf(['filled', 'ghost']),
    color: PropTypes.oneOf(['primary', 'secondary']),
    lightMode: PropTypes.oneOf(['1', '2', '3', 'none']),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    titleVariant: PropTypes.oneOf(['1', '2']),
    subTitle: PropTypes.string,
    label: PropTypes.string,
    labelVariant: PropTypes.oneOf(['1', '2']),
    actionView: PropTypes.node
};
