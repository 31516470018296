import styled from 'styled-components';

import { fontSizes } from '../../../config/sizes';

export default function (UnStyledStartCardOne) {
    return styled(UnStyledStartCardOne)`
        > span {
            display: inline-block;

            :first-child {
                font-size: 1rem;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 0.75rem;
            }
        }

        > section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 1.5rem;

            > span {
                font-size: ${fontSizes['2xl']};
                font-weight: bold;
            }
        }
    `;
}
