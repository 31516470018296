import styled, { keyframes } from 'styled-components';

const hide = keyframes`
    from {
        height: inherit;
    }
    to {
        height: 0;
    }
`;

export default function (UnStyledExpandableListItem) {
    return styled(UnStyledExpandableListItem)`
        padding: 0.75rem 0;
        border-bottom: ${getBorderBottom};
        list-style-type: none;

        > header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > section {
                flex: 1;
                display: flex;
                align-items: center;
                cursor: ${getHeaderSectionCursor};

                > span {
                    font-size: 24px;
                    color: #757a8c;
                }

                > div {
                    flex: 1;
                }
            }

            > aside {
                margin-left: 1rem;

                > img {
                    cursor: pointer;
                    transition: opacity 300ms ease-out;

                    :not(:last-child) {
                        margin-right: 0.7rem;
                    }

                    :hover {
                        opacity: 0.5;
                    }
                }
            }
        }

        > main {
            transform: scaleY(0);
            overflow-y: hidden;
            transform-origin: 50% 0;
            transition: transform 300ms ease-in-out;

            &.hidden {
                animation: ${hide} forwards;
                animation-delay: 200ms;
            }

            &.initial {
                height: 0;
            }
        }

        &.expanded {
            > header {
                > section {
                    > span {
                        transform: rotate(180deg);
                    }
                }
            }

            > main {
                transform: scaleY(1);
            }
        }
    `;
}

function getBorderBottom({ index, itemsCount }) {
    return index === itemsCount - 1 ? 'none' : '1px solid #d6dce9';
}

function getHeaderSectionCursor({ clickable }) {
    return clickable ? 'pointer' : 'initial';
}
