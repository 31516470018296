import styled from 'styled-components';

import colors from '../../../config/colors';

export default function (UnStyledTableBody) {
    return styled(UnStyledTableBody)`
        margin-top: 1.5rem;

        > table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;

            > thead {
                background: ${colors.table.header};

                > tr {
                    vertical-align: top;

                    > th {
                        font-weight: 600;
                        color: ${colors.input.label};
                        padding: 0.6rem 0.5rem;
                        white-space: nowrap;

                        > div {
                            display: flex;
                            align-items: center;

                            &.actions {
                                justify-content: center;
                            }

                            > span {
                                display: inline-block;
                                margin-right: 0.5rem;
                            }
                        }
                    }
                }
            }

            > tbody {
                > tr {
                    vertical-align: baseline;

                    > td {
                        padding: 0.6rem 0.5rem 0.55rem 0.5rem;
                        vertical-align: middle;
                    }
                }
            }
        }
    `;
}
