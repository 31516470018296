import React from 'react';
import PropTypes from 'prop-types';

import styleTableFooter from './utils/style-table-footer';

import Text from '../Text';
import Pagination from '../Pagination';

function UnStyledTableFooter({ className, currentItemsCountPerPage, currentPage, totalItemsCount, pagesCount, onPageChange }) {
    return (
        <footer className={className}>
            <Text.Label>
                Résultats {getFirstItemPosition(currentItemsCountPerPage, currentPage)} à {getLastItemPosition(currentItemsCountPerPage, currentPage, totalItemsCount)} sur {totalItemsCount}
            </Text.Label>
            <Pagination pagesCount={pagesCount} onPageChange={onPageChange} currentPage={currentPage} />
        </footer>
    );
}

function getFirstItemPosition(currentItemsCountPerPage, currentPage) {
    const firstItemIndex = (currentPage - 1) * currentItemsCountPerPage;
    return firstItemIndex + 1;
}

function getLastItemPosition(currentItemsCountPerPage, currentPage, totalItemsCount) {
    const lastItemPosition = currentItemsCountPerPage * currentPage;

    return lastItemPosition > totalItemsCount ? totalItemsCount : lastItemPosition;
}

const StyledTableFooter = styleTableFooter(UnStyledTableFooter);

export function TableFooter({ currentItemsCountPerPage = 0, totalItemsCount = 0, pagesCount = 0, ...restProps }) {
    return <StyledTableFooter currentItemsCountPerPage={currentItemsCountPerPage} totalItemsCount={totalItemsCount} pagesCount={pagesCount} {...restProps} />;
}
TableFooter.propTypes = {
    currentItemsCountPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    totalItemsCount: PropTypes.number,
    pagesCount: PropTypes.number,
    onPageChange: PropTypes.func.isRequired
};
