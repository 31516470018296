import { useState, useEffect } from 'react';

import { isValidValue } from '../../../utils';

export default function (defaultId) {
    const [overflowing, setOverflowing] = useState(false);
    // for small screens
    const [currentIndex, setCurrentIndex] = useState(0);
    // for large screens
    const [currentFirstNonOverflowingElementIndex, setCurrentFirstNonOverflowingElementIndex] = useState(0);
    const [currentLastNonOverflowingElementIndex, setCurrentLastNonOverflowingElementIndex] = useState(0);

    useEffect(() => {
        const main = selectMainTag(defaultId);
        if (main && main.scrollWidth > main.clientWidth) setOverflowing(true);
    }, []);

    function handleNext() {
        const main = selectMainTag(defaultId);
        const slides = selectSlidesTags(defaultId);
        if (!isValidValue(main) || !isValidValue(slides) || slides.length === 0) return;

        const firstNextOverflowingElement = getFirstNextOverflowingElement(main, slides, currentIndex);
        if (firstNextOverflowingElement) {
            smoothScroll(firstNextOverflowingElement).then(() => resetNonOverflowingElementsIndex({ next: true }));
        }
    }

    function handlePrev() {
        const main = selectMainTag(defaultId);
        const slides = selectSlidesTags(defaultId);
        if (!isValidValue(main) || !isValidValue(slides) || slides.length === 0) return;

        const prevFirstOverflowingElement = getFirstPrevOverflowingElement(main, slides, currentIndex);
        if (prevFirstOverflowingElement) {
            smoothScroll(prevFirstOverflowingElement).then(() => resetNonOverflowingElementsIndex({ prev: true }));
        }
    }

    function resetNonOverflowingElementsIndex({ next = false, prev = false } = {}) {
        if (next && prev) return;

        const main = selectMainTag(defaultId);
        const slides = selectSlidesTags(defaultId);
        if (!isValidValue(main) || !isValidValue(slides) || slides.length === 0) return;

        const formattedSlides = formatSlides(main, slides);
        const firstNonOverflowingSlideIndex = getFirstNonOverflowingSlideIndex(formattedSlides);
        const lastNonOverflowingSlideIndex = getLastNonOverflowingSlideIndex(formattedSlides);
        let updatedCurrentIndex = currentIndex;
        if (next) updatedCurrentIndex++;
        else if (prev) updatedCurrentIndex--;

        setCurrentFirstNonOverflowingElementIndex(getValidIndex(firstNonOverflowingSlideIndex, updatedCurrentIndex));
        setCurrentLastNonOverflowingElementIndex(getValidIndex(lastNonOverflowingSlideIndex, updatedCurrentIndex));
        setCurrentIndex(updatedCurrentIndex);
    }

    function getValidIndex(index, defaultValue) {
        return index && index >= 0 ? index : defaultValue;
    }

    function smoothScroll(element) {
        return new Promise((resolve) => {
            let lastLeftPosition;
            let samePositionCount = 0;
            element.scrollIntoView({ behaviour: 'smooth', block: 'nearest', inline: 'start' });
            window.requestAnimationFrame(checkPosition);

            function checkPosition() {
                const { left } = element.getBoundingClientRect();
                if (left === lastLeftPosition) {
                    samePositionCount++;
                    if (samePositionCount >= 2) resolve();
                    else window.requestAnimationFrame(checkPosition);
                } else {
                    lastLeftPosition = left;
                    window.requestAnimationFrame(checkPosition);
                }
            }
        });
    }

    return {
        overflowing,
        currentFirstNonOverflowingElementIndex,
        currentLastNonOverflowingElementIndex,
        handleNext,
        handlePrev
    };
}

function selectMainTag(defaultId) {
    return document.querySelector(`${encodeDefaultId(defaultId)} > main`);
}

function selectSlidesTags(defaultId) {
    return document.querySelectorAll(`${encodeDefaultId(defaultId)} > main > *`);
}

function encodeDefaultId(defaultId = '') {
    const encodedDefaultIdParts = [];
    const digitsParts = defaultId.split('-');
    digitsParts.forEach((digitsPart) => {
        const encodedDigitsPart = digitsPart
            .split('')
            .map((digit) => {
                if (/^\d$/.test(digit)) return `\\3${digit} `;

                return digit;
            })
            .join('');
        encodedDefaultIdParts.push(encodedDigitsPart);
    });

    return '#'.concat(encodedDefaultIdParts.join('-'));
}

function getFirstNextOverflowingElement(main, slides, currentIndex) {
    const formattedSlides = formatSlides(main, slides);
    const lastNonOverflowingSlideIndex = getLastNonOverflowingSlideIndex(formattedSlides) || currentIndex;
    if (lastNonOverflowingSlideIndex === formattedSlides.length - 1) return;

    const nextOverflowingSlides = formattedSlides.slice(lastNonOverflowingSlideIndex + 1, formattedSlides.length);

    return nextOverflowingSlides[0].slide;
}

function getFirstPrevOverflowingElement(main, slides, currentIndex) {
    const formattedSlides = formatSlides(main, slides);
    const lastNonOverflowingSlideIndex = getLastNonOverflowingSlideIndex(formattedSlides) || currentIndex;
    if (lastNonOverflowingSlideIndex === 0) return;

    const prevOverflowingSlides = formattedSlides
        .slice(0, lastNonOverflowingSlideIndex)
        .filter(({ slideOverflowing }) => slideOverflowing);

    return prevOverflowingSlides[prevOverflowingSlides.length - 1].slide;
}

function formatSlides(main, slides) {
    return Array.from(slides).map((slide) => ({ slide, slideOverflowing: isSlideOverflowing(main, slide) }));
}

function getLastNonOverflowingSlideIndex(slides = []) {
    const nonOverflowingSlidesIndexes = slides
        .map((slide, index) => ({ ...slide, index }))
        .filter(({ slideOverflowing }) => !slideOverflowing)
        .map(({ index }) => index);

    return nonOverflowingSlidesIndexes[nonOverflowingSlidesIndexes.length - 1] || 0;
}

function getFirstNonOverflowingSlideIndex(slides = []) {
    const foundIndex = slides.findIndex(({ slideOverflowing }) => !slideOverflowing);

    return foundIndex >= 0 ? foundIndex : undefined;
}

function isSlideOverflowing(main, slide) {
    const mainBouding = main.getBoundingClientRect();
    const slideBouning = slide.getBoundingClientRect();

    return (
        Math.floor(slideBouning.left) < Math.floor(mainBouding.left) ||
        Math.floor(slideBouning.right - 1) > Math.floor(mainBouding.right)
    );
}
