import React from 'react';
import styled from 'styled-components';

import { translationModes } from '../../../utils/enums';
import renovationAddressHelper from '../../../utils/renovation-address.helper';
import { renovationAddressPropType } from '../../../utils/prop-types-schemas';

import TextRow from '../../TextRow';
import Text from '../../Text';

function UnStyledHousingsSummary({ className, renovationAddress = {} }) {
    const housings = renovationAddressHelper.groupHousingsBySituation(renovationAddress);

    return (
        <div className={className}>
            {housings.map(({ housingSituation, count }, index) => (
                <TextRow key={index}>
                    <Text.Label>Nombre de ménage(s) {renovationAddressHelper.formatHousingSituation(housingSituation, translationModes.LONG)}(s)</Text.Label>
                    <Text> : {count}</Text>
                </TextRow>
            ))}
        </div>
    );
}

const StyledHousingsSummary = styled(UnStyledHousingsSummary)``;

export default function HousingsSummary(props) {
    return <StyledHousingsSummary {...props} />;
}
HousingsSummary.propTypes = {
    renovationAddress: renovationAddressPropType
};
