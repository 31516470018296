import React from 'react';
import PropTypes from 'prop-types';

import closeIcon from '../../assets/img/close.svg';
import smallEditIcon from '../../assets/img/picto_smal_edit.svg';
import visibilityIcon from '../../assets/img/visibility.svg';
import { joinClassNames } from '../../utils/array';
import { getExpandableListItemClassName, getExpandableListItemMainClassName } from './utils';
import styleExpandableListItem from './utils/style-expandable-list-item';
import { LABEL_DELETE_EXPANDABLE_LIST_ITEM, LABEL_EDIT_EXPANDABLE_LIST_ITEM, LABEL_VIEW_EXPANDABLE_LIST_ITEM } from '../../utils/form_labels/common';

import useExpandableListItem from './hooks/useExpandableListItem';

function UnStyledExpandableListItem({ className, header, main, index, uniqueKey, selectedIndex, clickable, editable, viewable, updatable, deletable, labels, onClick, onEdit, onDelete, onView }) {
    const { expanded, hasDoneFirstExpanding, handleClick } = useExpandableListItem({ index, selectedIndex, uniqueKey, clickable, onClick, onView, onEdit, onDelete });
    const { view: LABEL_VIEW, edit: LABEL_EDIT, delete: LABEL_DELETE } = labels;

    return (
        <li className={joinClassNames(className, getExpandableListItemClassName(expanded))}>
            {header && (
                <header>
                    <section onClick={handleClick}>
                        {clickable && <span className='material-icons-outlined'>expand_more</span>}
                        <div>{header}</div>
                    </section>
                    {editable && (
                        <aside>
                            {viewable && <img src={visibilityIcon} alt='' role='button' aria-label={LABEL_VIEW || LABEL_VIEW_EXPANDABLE_LIST_ITEM} onClick={() => onView({ index, uniqueKey })} />}
                            {updatable && <img src={smallEditIcon} alt='' role='button' aria-label={LABEL_EDIT || LABEL_EDIT_EXPANDABLE_LIST_ITEM} onClick={() => onEdit({ index, uniqueKey })} />}
                            {deletable && <img src={closeIcon} alt='' role='button' aria-label={LABEL_DELETE || LABEL_DELETE_EXPANDABLE_LIST_ITEM} onClick={() => onDelete({ index, uniqueKey })} />}
                        </aside>
                    )}
                </header>
            )}
            {main && <main className={getExpandableListItemMainClassName(expanded, hasDoneFirstExpanding)}>{main}</main>}
        </li>
    );
}

const StyledExpandabledListItem = styleExpandableListItem(UnStyledExpandableListItem);

export function ExpandableListItem({
    index = 0,
    selectedIndex = -1,
    itemsCount = 1,
    clickable = true,
    editable = false,
    viewable = false,
    updatable = true,
    deletable = true,
    labels = {},
    ...restProps
}) {
    return (
        <StyledExpandabledListItem
            index={index}
            selectedIndex={selectedIndex}
            itemsCount={itemsCount}
            clickable={clickable}
            editable={editable}
            viewable={viewable}
            updatable={updatable}
            deletable={deletable}
            labels={labels}
            {...restProps}
        />
    );
}
ExpandableListItem.propTypes = {
    header: PropTypes.node.isRequired,
    main: PropTypes.node,
    index: PropTypes.number,
    uniqueKey: PropTypes.any,
    selectedIndex: PropTypes.number,
    itemsCount: PropTypes.number,
    clickable: PropTypes.bool,
    editable: PropTypes.bool,
    viewable: PropTypes.bool,
    updatable: PropTypes.bool,
    deletable: PropTypes.bool,
    labels: PropTypes.shape({
        view: PropTypes.string,
        edit: PropTypes.string,
        delete: PropTypes.string
    }),
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};
