import styled from 'styled-components';

import colors from '../../../config/colors';
import dimensions from '../../../config/dimensions';

export default function (UnStyledCard) {
    return styled(UnStyledCard)`
        border-radius: ${dimensions.radius};
        background: ${colors.white};
        min-height: ${dimensions.card.minHeight};
        height: ${(props) => props.customer && dimensions.card.customer.height};
        box-shadow: 0px 0px 8px ${(props) => getBoxShadowColor(props)};
    `;
}

function getBoxShadowColor({ color }) {
    return color === 'primary' ? '#90B1E166' : 'rgba(0,0,0,0.11)';
}
