import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import pictoExport from '../assets/img/picto_export.svg';
import pictoExportPrimary from '../assets/img/picto_export_primary.svg';

import colors from '../config/colors';

const UnStyledExportButton = ({ className, children, color, onClick }) => {
    return (
        <button className={className} onClick={onClick}>
            <div>
                <img src={color === 'primary' ? pictoExportPrimary : pictoExport} />
                <span>{children}</span>
            </div>
        </button>
    );
};

const StyledExportButton = styled(UnStyledExportButton)`
    display: inline-block;
    background: var(--color-white);
    border: ${(props) => `1px solid ${props.color === 'primary' ? colors.primaryLight3 : '#31AEAC'}`};
    padding: 0.325rem 1.25rem;
    border-radius: var(--dimen-input-border-radius);
    color: ${(props) => (props.color === 'primary' ? colors.primaryLight3 : '#31AEAC')};
    font-weight: 500;
    transition: all 200ms ease-in-out;
    font-size: var(--input-size);
    min-height: 37.2px;

    :focus {
        outline: none;
    }

    :hover {
        background: ${(props) => (props.color === 'primary' ? colors.primaryLight1 : colors.secondaryLight1)};
    }

    > div {
        > img {
            display: inline-block;
            margin-right: 0.75rem;
            height: 1.25rem;
        }
    }
`;

const ExportButton = (props) => <StyledExportButton {...props} />;

ExportButton.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', '']),
    className: PropTypes.string,
    onClick: PropTypes.func
};
ExportButton.defaultProps = {
    color: 'secondary',
    className: '',
    onClick: () => {}
};

export default ExportButton;
