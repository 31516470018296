import React from 'react';
import PropTypes from 'prop-types';

import styleStatCard from './utils/style-stat-card';

import { Card } from '../card';
import { StatCardOne } from './StatCardOne';
import { StatCardTwo } from './StatCardTwo';

const UnStyledStatCard = ({ className, style, type, ...restProps }) => {
    const SelectedStatCard = { 1: StatCardOne, 2: StatCardTwo }[type];

    return (
        <Card className={className} style={style}>
            {SelectedStatCard && <SelectedStatCard {...restProps} />}
        </Card>
    );
};

const StyledStatCard = styleStatCard(UnStyledStatCard);

export const StatCard = ({ type = '1', color = 'primary', lightMode = 'none', options = [], ...restProps }) => {
    return <StyledStatCard type={type} color={color} lightMode={lightMode} options={options} {...restProps} />;
};
StatCard.propTypes = {
    style: PropTypes.shape({}),
    type: PropTypes.oneOf(['1', '2']),
    color: PropTypes.oneOf(['primary', 'secondary']),
    lightMode: PropTypes.oneOf(['1', '2', '3', 'none']),
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any
        })
    ),
    actionView: PropTypes.node,
    onSelectOption: PropTypes.func
};
