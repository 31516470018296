import React from 'react';
import PropTypes from 'prop-types';

import styleCard from './utils/style-card';

const UnStyledCard = ({ testId, className, style = {}, children }) => {
    return (
        <div data-testid={testId} className={className} style={style}>
            {children}
        </div>
    );
};

const StyledCard = styleCard(UnStyledCard);

export const Card = ({ color = 'primary', ...restProps }) => {
    return <StyledCard color={color} {...restProps} />;
};
Card.propTypes = {
    testId: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary']),
    style: PropTypes.shape({})
};
