import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../config/colors';

function UnStyledAnchor({ className, href, children, onClick }) {
    function handleClick(evt) {
        if (onClick) {
            evt.preventDefault();
            onClick();
        }
    }

    return (
        <a className={className} href={href} target='_blank' rel='noopener noreferrer' onClick={handleClick}>
            {children}
        </a>
    );
}

const StyledAnchor = styled(UnStyledAnchor)`
    color: ${colors.primary};
    text-decoration: underline;
`;

export default function Anchor(props) {
    return <StyledAnchor {...props} />;
}
Anchor.propTypes = {
    href: PropTypes.string,
    onClick: PropTypes.func
};
