import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Text from '../Text';
import TextBold from '../TextBold';
import TextRow from '../TextRow';
import Body from './Body';
import Anchor from '../../utils/anchor';

const UnStyledItemContactHeader = ({ className, contact }) => {
    return (
        <div className={className}>
            <TextBold>{contact.lastName?.toUpperCase()}</TextBold>
            <Text>{contact.firstName}</Text>
            <TextBold color='primary'>{contact.role}</TextBold>
            <TextBold italic>{contact.isMainContact ? 'Signataire' : <span style={{ color: 'white' }}>Corporate</span>}</TextBold>
        </div>
    );
};

const StyledItemContactHeader = styled(UnStyledItemContactHeader)`
    display: grid;
    grid-template-columns: 2.25fr 2.25fr 3fr 0.5fr;
    gap: 0.5rem;
`;

const ItemContactHeader = (props) => <StyledItemContactHeader {...props} />;

ItemContactHeader.propTypes = {
    contact: PropTypes.shape({})
};

ItemContactHeader.defaultProps = {
    contact: {}
};

const UnStyledItemContactBody = ({ className, contact }) => {
    return (
        <div className={className}>
            {(contact.email || contact.phone || contact.mobile) && (
                <Body>
                    {contact.email && (
                        <TextRow>
                            <Text.Label>Adresse email : </Text.Label>
                            <Text>{contact.email}</Text>
                        </TextRow>
                    )}
                    <section>
                        {contact.phone && (
                            <TextRow>
                                <Text.Label>Téléphone fixe : </Text.Label>
                                <Text>{contact.phone}</Text>
                            </TextRow>
                        )}
                        {contact.mobile && (
                            <TextRow>
                                <Text.Label>Téléphone mobile : </Text.Label>
                                <Text>{contact.mobile}</Text>
                            </TextRow>
                        )}
                    </section>
                    {contact.signatureFileUrl && (
                        <TextRow>
                            <TextBold.Label>Lien vers l'image de signature et du cachet : </TextBold.Label>
                            <Anchor href={contact.signatureFileUrl}>Fichier</Anchor>
                        </TextRow>
                    )}
                </Body>
            )}
        </div>
    );
};

const StyledItemContactBody = styled(UnStyledItemContactBody)`
    > ${Body} {
        > section {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > ${TextRow} {
                :first-child {
                    margin-right: 1rem;
                }
            }
        }
    }
`;

const ItemContactBody = (props) => <StyledItemContactBody {...props} />;

ItemContactBody.propTypes = {
    contact: PropTypes.shape({})
};

ItemContactBody.defaultProps = {
    contact: {}
};

const ItemContact = { Header: ItemContactHeader, Body: ItemContactBody };

export default ItemContact;
