import React from 'react';
import PropTypes from 'prop-types';

import styleStatSelect from './utils/style-stat-select';
import { getOptionClassName } from './utils/stat-select';
import { joinClassNames } from '../../utils/array';
import classes from '../../config/classes';
import styles from '../../styles.module.css';

import useStatSelect from './hooks/useStatSelect';

const UnStyledStatSelect = ({ className, options, onSelectOption }) => {
    const { selectedOption, handleSelect } = useStatSelect(options, onSelectOption);

    return (
        <div className={className}>
            {options.map((option, index) => (
                <button
                    key={index}
                    className={joinClassNames(
                        styles[classes.button],
                        classes.button,
                        getOptionClassName(option, selectedOption)
                    )}
                    onClick={() => handleSelect(option)}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
};

const StyledStatSelect = styleStatSelect(UnStyledStatSelect);

export const StatSelect = ({ options = [], ...restProps }) => {
    return <StyledStatSelect options={options} {...restProps} />;
};
StatSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any
        })
    ),
    onSelectOption: PropTypes.func
};
