import React, { isValidElement, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextBold from './TextBold';

import { Card } from './card';

function UnStyledCommentCard({ testId, className, title, icon, actionView, actionViewVisible, text }) {
    const [isExtended, setIsExtended] = useState(false);

    function handleClick() {
        setIsExtended(!isExtended);
    }

    return (
        <Card testId={testId}>
            <div className={className}>
                <header>
                    <div>
                        <img src={icon} alt='' />
                        <TextBold.Label>{title}</TextBold.Label>
                    </div>
                    {actionViewVisible && isValidElement(actionView) && actionView}
                </header>
                <div />
                <main>
                    <div>
                        <TextBold className='editable-text'>{isExtended ? text : text.slice(0, 200)}</TextBold>
                    </div>
                    {text.length > 250 && (
                        <div onClick={handleClick}>
                            <span>{isExtended ? 'Voir moins' : 'Lire la suite'}</span>
                            <span className='material-icons'>{isExtended ? 'expand_less' : 'expand_more'}</span>
                        </div>
                    )}
                </main>
            </div>
        </Card>
    );
}

const StyledCommentCard = styled(UnStyledCommentCard)`
    padding: 1rem 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;

    > header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            display: flex;
            align-items: center;

            > img {
                margin-right: ${getImgMarginRight};
            }
        }
    }
    > div {
        height: 1px;
        border: 1px solid #d6dce9;
        opacity: 0.4;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
        width: 100%;
    }

    > main {
        > :nth-child(2) {
            margin-top: 0.5rem;
            font-weight: 500;
            color: #74798c;
            float: right;
            display: flex;

            > :nth-child(1) {
                margin-top: 0.15rem;
            }
        }
    }
`;

function getImgMarginRight({ icon }) {
    return icon ? '0.75rem' : 0;
}

export default function CommentCard({ actionViewVisible = true, ...restProps }) {
    return <StyledCommentCard actionViewVisible={actionViewVisible} {...restProps} />;
}
CommentCard.propTypes = {
    testId: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.any,
    actionView: PropTypes.node,
    actionViewVisible: PropTypes.bool
};
