import { useState, useEffect } from 'react';

export default function ({ index, selectedIndex, uniqueKey, clickable, onClick, onView, onEdit, onDelete } = {}) {
    const [expanded, setExpanded] = useState(false);
    const [hasDoneFirstExpanding, setHasDoneFirstExpanding] = useState(false);
    useEffect(() => {
        setExpanded(index === selectedIndex);
    }, [selectedIndex]);

    useEffect(() => {
        if (expanded && !hasDoneFirstExpanding) setHasDoneFirstExpanding(true);
    }, [expanded]);

    function handleClick() {
        if (!clickable) return;

        setExpanded(!expanded);
        if (!onClick) return;
        if (!expanded) onClick(index);
        else onClick(-1);
    }

    function hanldeView() {
        if (onView) onView({ index, uniqueKey });
    }

    function hanldeEdit() {
        if (onEdit) onEdit({ index, uniqueKey });
    }

    function hanldeDelete() {
        if (onDelete) onDelete({ index, uniqueKey });
    }

    return { expanded, hasDoneFirstExpanding, handleClick, hanldeView, hanldeEdit, hanldeDelete };
}
