import styled from 'styled-components';
import colors from '../../config/colors';

export default styled.div`
    > input {
        display: none;
    }

    > main {
        > label {
            display: block;
            font-weight: ${getLabelFontWeight};
        }
    }

    > footer {
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-top: 0.5rem;

        > a {
            display: inline-block;
            color: ${colors.primary};
            transition: opacity 200ms ease;
            margin-right: 0.25rem;

            :hover {
                opacity: 0.8;
            }
        }
    }
`;

function getLabelFontWeight({ labelStyle }) {
    return { normal: 400, medium: 500, bold: 700 }[labelStyle];
}
