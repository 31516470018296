import React from 'react';
import PropTypes from 'prop-types';

import styleMenuPopover from './utils/style-menu-popover';

const UnStyledMenuPopover = ({ className, children }) => {
    return (
        <div role='menu' className={className}>
            <div>
                <div>{children}</div>
            </div>
        </div>
    );
};

const StyledMenuPopover = styleMenuPopover(UnStyledMenuPopover);

export const MenuPopover = (props) => <StyledMenuPopover {...props} />;
MenuPopover.propTypes = {
    size: PropTypes.string.isRequired
};
