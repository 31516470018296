import styled from 'styled-components';

import colors from '../../../config/colors';
import dimensions from '../../../config/dimensions';
import zIndices from '../../../config/z-indices';

export default function (UnStyledMenuPopover) {
    return styled(UnStyledMenuPopover)`
        width: ${(props) => getWidth(props)};
        position: absolute;
        top: 100%;
        right: 0;
        background: transparent;
        z-index: ${zIndices.popover};
        opacity: 0;
        transform: scale(0);
        transform-origin: 100% 100%;
        transition: all 250ms ease-in-out;

        > div {
            position: relative;
            max-width: 100%;

            > div {
                position: absolute;
                min-width: 50%;
                top: 0;
                right: 0;
                margin-top: 0.5rem;
                background: ${colors.white};
                padding: 0.5rem 0;
                border-radius: ${dimensions.radius};
                box-shadow: 0 0 5px 0 rgba(144, 177, 225, 0.38);
            }
        }

        &.shown {
            transform: scale(1);
            opacity: 1;
        }

        &.hidden {
            transform: scale(0);
            opacity: 0;
        }
    `;
}

function getWidth({ size }) {
    return dimensions.popover.width[size];
}
