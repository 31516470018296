import React from 'react';
import PropTypes from 'prop-types';

import styleActionButton from './utils/style-action-button';
import { joinClassNames } from '../../utils/array';
import { getIconClassName } from '../../utils/button';
import classes from '../../config/classes';
import styles from '../../styles.module.css';

const UnStyledActionButton = ({ className, icon, label, iconStyle, disabled, onClick }) => {
    function handleClick() {
        if (onClick) onClick();
    }

    return (
        <button className={joinClassNames(className, styles[classes.button], classes.button)} disabled={disabled} aria-label={label} onClick={handleClick}>
            <main>
                <span className={getIconClassName(iconStyle)}>{icon}</span>
            </main>
        </button>
    );
};

const StyledActionButton = styleActionButton(UnStyledActionButton);

export const ActionButton = ({ colorScheme = 'primary', variant = 'rounded', iconStyle = 'filled', size = 'md', disabled = false, ...restProps }) => (
    <StyledActionButton colorScheme={colorScheme} variant={variant} iconStyle={iconStyle} size={size} disabled={disabled} {...restProps} />
);
ActionButton.propTypes = {
    colorScheme: PropTypes.oneOf(['primary']),
    variant: PropTypes.oneOf(['rounded', 'circle']),
    label: PropTypes.string,
    icon: PropTypes.oneOf(['add', 'close', 'delete', 'edit', 'visibility', 'more_horiz', 'textsms', 'clear']),
    iconStyle: PropTypes.oneOf(['filled', 'outline']),
    size: PropTypes.oneOf(['sm', 'md']),
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};
