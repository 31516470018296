import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { v1 as uuid } from 'uuid';

import { Card } from './card';

import colors from '../config/colors';
import utils from '../utils';
import enums from '../utils/enums';

const UnStyledSelect = ({ className, options, onChange }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onClick = (index, option) => {
        setSelectedIndex(index);
        onChange({ index, option });
    };

    return (
        <div className={className}>
            {options.map((option, index) => (
                <button
                    className={selectedIndex === index ? 'selected' : ''}
                    key={uuid()}
                    onClick={() => onClick(index, option)}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
};

const Select = styled(UnStyledSelect)`
    > button {
        padding: 0.3rem 1rem;
        border-radius: 24px;
        border: 1px solid var(--color-white);
        background: var(--color-white);
        margin-right: 1rem;
        font-size: 0.7.5rem;
        color: #74798c;
        transition: all 300ms ease-in-out;

        :focus {
            outline: none;
        }

        :hover {
            border-color: #74798c;
        }

        &.selected {
            background: #f5f6fa;
            font-weight: 600;

            :hover {
                border-color: var(--color-white);
            }
        }
    }
`;

const UnStyledMainCardOne = ({
    className,
    title,
    icon,
    value,
    valueToPrice,
    actionView,
    buttonLabel,
    buttonColor,
    buttonLightMode,
    buttonIcon,
    buttonOnClick,
    selectOptions,
    selectOnChange
}) => {
    return (
        <div className={className}>
            <div>
                <h5>{title ? title.toUpperCase() : ''}</h5>
                <Select options={selectOptions} onChange={selectOnChange} />
            </div>
            <div>
                <div>
                    <img src={icon} alt='' />
                    <h2>{valueToPrice ? utils.formatPriceValue(value) : value}</h2>
                </div>
                {actionView && actionView}
            </div>
        </div>
    );
};

const MainCardOne = styled(UnStyledMainCardOne)`
    padding: 1.5rem 2rem;
    color: ${(props) =>
        props.foregroundColor ? utils.extractColor(props.foregroundColor, props.foregroundLightMode) : colors.primary};
    display: flex;
    flex-direction: column;
    min-height: 184px;

    > div {
        :first-child {
            > h5 {
                font-size: 1rem;
                font-weight: 620;
                margin-bottom: 0.75rem;
            }
        }

        :last-child {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding-top: 2rem;
        }

        > div {
            display: flex;
            align-items: center;

            > h2 {
                font-size: 2.07rem;
                font-weight: 600;
                margin: 0 0 0 1rem;
            }
        }
    }
`;

const UnStyledMainCardTwo = ({ className, icon, title, value, actionView }) => {
    return (
        <div className={className}>
            <div>
                <div>
                    <img src={icon} alt='' />
                </div>
                <h1>{value}</h1>
                <p>{title}</p>
            </div>
            <div>{actionView && actionView}</div>
        </div>
    );
};

const MainCardTwo = styled(UnStyledMainCardTwo)`
    padding: 2rem 1.5rem;
    color: ${(props) =>
        props.foregroundColor ? utils.extractColor(props.foregroundColor, props.foregroundLightMode) : colors.primary};
    min-height: 320px;
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        flex-direction: column;

        :first-child {
            align-items: center;

            > div {
                margin-top: 1rem;
                height: 54px;
                display: flex;
                align-items: center;

                img {
                    max-height: 54px;
                }
            }

            > h1 {
                font-size: 2.65rem;
                font-weight: 600;
                margin: 1.5rem 0 0.5rem 0;
            }

            > p {
                font-size: 15px;
                font-weight: 600;
                text-align: center;
                margin: 1rem;
            }
        }

        :last-child {
            flex: 1;
            justify-content: flex-end;
        }
    }
`;

const UnStyledMainCard = ({ className, variant, ...props }) => {
    let card;
    switch (variant) {
        case '1':
            card = <MainCardOne {...props} />;
            break;
        case '2':
            card = <MainCardTwo {...props} />;
            break;
        default:
            card = <MainCardOne {...props} />;
            break;
    }

    return (
        <Card color='primary'>
            <div className={className}>{card}</div>
        </Card>
    );
};

const StyledMainCard = styled(UnStyledMainCard)`
    background: ${(props) =>
        props.backgroundColor ? utils.extractColor(props.backgroundColor, props.backgrounLightMode) : colors.white};
    border-radius: 5px;
`;

const MainCard = (props) => <StyledMainCard {...props} />;

MainCard.propTypes = {
    variant: PropTypes.oneOf(['1', '2', '']),
    backgroundColor: PropTypes.oneOf(enums.colors),
    backgrounLightMode: PropTypes.oneOf(enums.lightModes),
    foregroundColor: PropTypes.oneOf(enums.colors),
    foregroundLightMode: PropTypes.oneOf(enums.lightModes),
    title: PropTypes.string,
    icon: PropTypes.any,
    value: PropTypes.number,
    valueToPrice: PropTypes.bool,
    actionView: PropTypes.node,
    selectOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any
        })
    ),
    selectOnChange: PropTypes.func
};

MainCard.defaultProps = {
    variant: '1',
    backgroundColor: null,
    backgrounLightMode: null,
    foregroundColor: null,
    foregroundLightMode: null,
    title: null,
    icon: null,
    value: 0,
    valueToPrice: true,
    actionView: null,
    selectOptions: [],
    selectOnChange: () => {}
};

export default MainCard;

export { Select };
