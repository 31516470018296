import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';

import itemCountPerPageOptions from './utils/item-count-per-page-options';
import styleTableHeader from './utils/style-table-header';
import { actionsPropType } from './utils/default-prop-types';

import Text from '../Text';
import Select from '../inputs/Select';

function UnStyledTableHeader({ className, totalItemsCount, actions, onItemsCountPerPageChange, defaultCurrentItemsCountPerPage }) {
    function handleChange({ value }) {
        onItemsCountPerPageChange(value);
    }

    return (
        <header className={className}>
            <section>
                <Text color='primary'>Voir</Text>
                <Select options={itemCountPerPageOptions} placeholder={`${defaultCurrentItemsCountPerPage}`} disabled={totalItemsCount === 0} onChange={handleChange} />
                <Text color='primary'>Résultats</Text>
            </section>
            {isValidElement(actions) && <section>{actions}</section>}
        </header>
    );
}

const StyledTableHeader = styleTableHeader(UnStyledTableHeader);

export function TableHeader(props) {
    return <StyledTableHeader {...props} />;
}
TableHeader.propTypes = {
    totalItemsCount: PropTypes.number.isRequired,
    actions: actionsPropType,
    onItemsCountPerPageChange: PropTypes.func.isRequired
};
