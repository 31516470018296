import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import enums, { maPrimeRenovLabels } from '../../utils/enums';
import entitiesUtils from '../../utils/entities-utils';

import TextBold from '../TextBold';
import Text from '../Text';
import Button from '../Button';
import TextRow from '../TextRow';
import { Field } from '../input';
import Box from '../Box';
import Body from './Body';
import HouseholdIncomeBonification from './item_work_address/HouseholdIncomeBonification';
import QPVBonification from './item_work_address/QPVBonification';
import HousingsSummary from './item_work_address/HousingsSummary';

function getHeaderFirstItemLabel({ addressName, buildingSector, housingType }) {
    if (buildingSector && addressName) return addressName;

    return enums.renovationAddressHousingTypes[housingType];
}

function getStreetLabel({ streetName, streetNumber }) {
    const streetLabel = streetNumber ? `${streetNumber}, ` : '';

    return streetLabel.concat(streetName);
}

const UnStyledItemWorkAddressHeader = ({ className, address }) => {
    return (
        <div className={className}>
            <TextBold>{getHeaderFirstItemLabel(address)}</TextBold>
            <Text>{getStreetLabel(address)}</Text>
            <Text>{address.postalCode}</Text>
            <Text>{address.city}</Text>
        </div>
    );
};

const StyledItemAddressWorkHeader = styled(UnStyledItemWorkAddressHeader)`
    display: grid;
    grid-template-columns: ${(props) => (props.isPMAddress ? '2fr 2fr 3fr 1.2fr 1.2fr' : '2fr 3fr 1.2fr 1.2fr')};
    gap: 0.5rem;
    align-items: center;
`;

const ItemWorkAddressHeader = (props) => <StyledItemAddressWorkHeader {...props} />;

ItemWorkAddressHeader.propTypes = {
    address: PropTypes.shape({}),
    isPMAddress: PropTypes.bool
};
ItemWorkAddressHeader.defaultProps = {
    address: {}
};

function displayCustomerPMRenovationAddressInfomation({ buildingSector, activityArea, housingType, phone }) {
    return (
        buildingSector && (
            <React.Fragment>
                <TextRow>
                    <Text.Label>Secteur du bâtiment : </Text.Label>
                    <Text>{enums.renovationAddressBuildingSectors[buildingSector]}</Text>
                </TextRow>
                {activityArea && (
                    <TextRow>
                        <Text.Label>Secteur d'activité : </Text.Label>
                        <Text>{enums.renovationAddressActivityAreas[activityArea]}</Text>
                    </TextRow>
                )}
                {housingType && (
                    <TextRow>
                        <Text.Label>Type de logement : </Text.Label>
                        <Text>{enums.renovationAddressHousingTypes[housingType]}</Text>
                    </TextRow>
                )}
                {phone && (
                    <TextRow>
                        <Text.Label>Téléphone : </Text.Label>
                        <Text>{phone}</Text>
                    </TextRow>
                )}
            </React.Fragment>
        )
    );
}

function getQPVBonificationButtonLabel({ qpvBonification } = {}) {
    if (qpvBonification && qpvBonification.qpvName && qpvBonification.qpvCode) return 'Modifier';

    return 'Ajouter un QPV';
}

const ItemWorkAddressBody = ({
    address,
    customer = {},
    showCustomerPMBonifications = false,
    onCreateOccupant,
    onEditOccupant,
    onDeleteOccupant,
    onEditQPVBonification,
    onEditTotalHousingCounts,
    onEditTotalNumberOfConcernedHouseholdsByTheOperation,
    onEditTotalNumberOfConcernedHouseholdsByTheOperationBeingConventional
}) => {
    return (
        <React.Fragment>
            <Body>
                {address.addressName && (
                    <TextRow>
                        <Text.Label>Nom : </Text.Label>
                        <Text>{address.addressName}</Text>
                    </TextRow>
                )}
                {displayCustomerPMRenovationAddressInfomation(address)}
                {address.climatZone && (
                    <TextRow>
                        <Text.Label>Zone climatique : </Text.Label>
                        <Text>{address.climatZone}</Text>
                    </TextRow>
                )}
                {address.cadastralParcelNumber && (
                    <TextRow>
                        <Text.Label>Numéro de parcelle cadastrale : </Text.Label>
                        <Text>{address.cadastralParcelNumber}</Text>
                    </TextRow>
                )}
                {address.customerIsHouseOwner && (
                    <TextRow>
                        <Text>Le client est propriétaire du logement.</Text>
                    </TextRow>
                )}

                {showCustomerPMBonifications && (
                    <React.Fragment>
                        {address.socialLandlordBonification.enabled && (
                            <React.Fragment>
                                <TextRow className='mt-3'>
                                    <TextBold color='primary' asSubTitle>
                                        Bonification CEE pour bailleurs sociaux : logements conventionnés
                                    </TextBold>
                                </TextRow>
                                <Box width='50%' marginBottom='1rem'>
                                    <Field
                                        fluid
                                        type='number'
                                        min={1}
                                        label="(*) Nombre total de ménage concernés par l'opération :"
                                        defaultValue={address.socialLandlordBonification.totalNumberOfConcernedHouseholdsByTheOperation}
                                        onBlur={onEditTotalNumberOfConcernedHouseholdsByTheOperation}
                                    />
                                </Box>
                                <Box width='50%' marginBottom='1rem'>
                                    <Field
                                        fluid
                                        type='number'
                                        min={1}
                                        max={address.socialLandlordBonification.totalNumberOfConcernedHouseholdsByTheOperation}
                                        label="(*) Nombre total de ménage concernés par l'opération habitant un logement conventionné :"
                                        defaultValue={address.socialLandlordBonification.totalNumberOfConcernedHouseholdsByTheOperationBeingConventional}
                                        onBlur={onEditTotalNumberOfConcernedHouseholdsByTheOperationBeingConventional}
                                    />
                                </Box>
                            </React.Fragment>
                        )}

                        {address.qpvBonification.enabled && (
                            <React.Fragment>
                                <TextRow className='mt-3'>
                                    <TextBold color='primary' asSubTitle>
                                        Bonification CEE pour les Quartiers Prioritaires de la Ville (QPV)
                                    </TextBold>
                                </TextRow>
                                <QPVBonification renovationAddress={address} />
                                <Button variant='outline' onClick={() => onEditQPVBonification(address)}>
                                    {getQPVBonificationButtonLabel(address)}
                                </Button>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {address.householdIncomeBonification.enabled && (
                    <React.Fragment>
                        <TextRow className='mt-3'>
                            <TextBold color='primary' asSubTitle>
                                Situation du/des ménages(s)
                            </TextBold>
                        </TextRow>
                        {entitiesUtils.isCustomerPM(address) && (
                            <Box width='50%' marginBottom='1rem'>
                                <Field fluid type='number' label='Nombre de logements' defaultValue={address.householdIncomeBonification.totalHousingsCount} onBlur={onEditTotalHousingCounts} />
                            </Box>
                        )}

                        <HousingsSummary renovationAddress={address} />

                        {!entitiesUtils.isCustomerPM(address) && address.maPrimeRenovType !== 'NONE' && (
                            <TextRow>
                                <Text>{`Profil MaPrimeRénov' : ${
                                    maPrimeRenovLabels[address.maPrimeRenovType]
                                } (sous réserve que le client soit propriétaire du logement à l'adresse des travaux)`}</Text>
                            </TextRow>
                        )}
                        <HouseholdIncomeBonification renovationAddress={address} onEditOccupant={onEditOccupant} onDeleteOccupant={onDeleteOccupant} />
                        <Button variant='outline' onClick={() => onCreateOccupant(address)}>
                            Ajouter un occupant
                        </Button>
                    </React.Fragment>
                )}
            </Body>
        </React.Fragment>
    );
};

ItemWorkAddressBody.propTypes = {
    address: PropTypes.shape({}),
    showCustomerPMBonification: PropTypes.bool,
    onCreateOccupant: PropTypes.func,
    onEditOccupant: PropTypes.func,
    onDeleteOccupant: PropTypes.func,
    onEditQPVBonification: PropTypes.func,
    onEditTotalHousingCounts: PropTypes.func,
    onEditTotalNumberOfConcernedHouseholdsByTheOperation: PropTypes.func,
    onEditTotalNumberOfConcernedHouseholdsByTheOperationBeingConventional: PropTypes.func
};

const ItemAddress = {
    Header: ItemWorkAddressHeader,
    Body: ItemWorkAddressBody
};

export default ItemAddress;
