import React from 'react';
import PropTypes from 'prop-types';

import TextBold from '../../TextBold';
import TextRow from '../../TextRow';

function QPVBonification({ renovationAddress }) {
    const { qpvBonification } = renovationAddress;

    return (
        <React.Fragment>
            {qpvBonification.qpvCode && (
                <TextRow>
                    <TextBold.Label>Code du QPV : </TextBold.Label>
                    <TextBold>{qpvBonification.qpvCode}</TextBold>
                </TextRow>
            )}
            {qpvBonification.qpvName && (
                <TextRow className='mb-3'>
                    <TextBold.Label>Nom du QPV : </TextBold.Label>
                    <TextBold>{qpvBonification.qpvName}</TextBold>
                </TextRow>
            )}
        </React.Fragment>
    );
}

QPVBonification.propTypes = {
    renovationAddress: PropTypes.shape({
        qpvBonification: PropTypes.shape({
            qpvCode: PropTypes.string,
            qpvName: PropTypes.string
        })
    })
};

export default QPVBonification;
