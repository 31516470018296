import styled from 'styled-components';

import { zoomIn, fadeIn } from '../../../config/animations';

export default function (UnStyledImageSlider) {
    return styled(UnStyledImageSlider)`
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        overflow: hidden;

        > div {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            flex: 1;
            max-height: ${getImageContainerMaxHeight};

            > img {
                display: inline-block;
                position: relative;
                max-width: 100%;
                max-height: 100%;
                transform-origin: center center;
                transform: scale(0);
                opacity: 0;
            }

            &.default {
                > img {
                    transform: scale(1);
                    opacity: 1;
                }
            }

            &.hidden {
                display: none;
            }

            &.shown {
                > img {
                    animation: ${zoomIn} 300ms linear forwards, ${fadeIn} 500ms linear forwards;
                }
            }

            > span {
                margin: 1rem 0;
                white-space: pre-wrap;
                word-wrap: break-word;
            }
        }

        > footer {
            display: flex;
            align-items: flex-end;
            padding: 2rem 0 0 0;
        }
    `;
}

function getImageContainerMaxHeight({ slides }) {
    return slides.length > 1 ? 'calc(100% - 4rem)' : '100%';
}
