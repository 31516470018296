import styled, { keyframes } from 'styled-components';

import colors from '../../../config/colors';

const bounce = keyframes`
  from {
    width: 0.1rem;
    height: 0.1rem;
    opacity: 1;
    transform: translate3d(0);
  }
  to {
    width: 1rem;
    height: 1rem;
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
`;

export default function (UnStyledLoader) {
    return styled(UnStyledLoader)`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2.5rem 0 1.5rem 0;
        height: 5rem;

        > span {
            background: ${colors.primary};
            border-radius: 50%;
            margin: 0 0.5rem;
            animation: ${bounce} 0.6s infinite alternate;

            :nth-child(2) {
                animation-delay: 0.2s;
            }
            :nth-child(3) {
                animation-delay: 0.4s;
            }
        }
    `;
}
