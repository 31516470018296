import styled from 'styled-components';

import colors from '../../config/colors';
import { fontSizes } from '../../config/sizes';

export const FieldLabel = styled.label`
    display: inline-block;
    margin-bottom: 0.25rem;
    color: ${colors.input.label};
    font-size: ${fontSizes.sm};
`;
