import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckIcon from '../../assets/img/picto_check_circle.svg';
import ErrorIcon from '../../assets/img/picto_error.svg';
import enums from '../../utils/enums';

import Text from '../Text';
import TextBold from '../TextBold';
import TextRow from '../TextRow';
import Body from './Body';

const statusIcons = {
    CONFIRMED: CheckIcon,
    REJECTED: ErrorIcon
};

const UnStyledItemKbisHeader = ({ className, kbis }) => {
    return (
        <div className={className}>
            {Object.keys(statusIcons).includes(kbis.status) && <img src={statusIcons[kbis.status]} alt='' />}
            <TextBold>EXTRAIT KBIS</TextBold>
        </div>
    );
};

const StyledItemKbisHeader = styled(UnStyledItemKbisHeader)`
    display: flex;
    align-items: center;

    > img {
        margin-right: 8px;
        height: 16px;
    }
`;

const ItemKbisHeader = (props) => <StyledItemKbisHeader {...props} />;

ItemKbisHeader.propTypes = {
    Kbis: PropTypes.shape({})
};

ItemKbisHeader.defaultProps = {
    Kbis: {}
};

const UnStyledItemKbisBody = ({ className, kbis }) => {
    return (
        <div className={className}>
            <Body>
                <TextRow>
                    <Text.Label>Statut : </Text.Label>
                    <TextBold>{enums.organizationDocumentStatusTranslations[kbis.status]}</TextBold>
                </TextRow>
                {kbis.startDate && (
                    <TextRow>
                        <Text.Label>Date d'émission : </Text.Label>
                        <Text>{new Date(kbis.startDate).toLocaleDateString()}</Text>
                    </TextRow>
                )}
            </Body>
        </div>
    );
};

const StyledItemKbisBody = styled(UnStyledItemKbisBody)``;

const ItemKbisBody = (props) => <StyledItemKbisBody {...props} />;

ItemKbisBody.propTypes = {
    kbis: PropTypes.shape({})
};

ItemKbisBody.defaultProps = {
    kbis: {}
};

const ItemKbis = {
    Header: ItemKbisHeader,
    Body: ItemKbisBody
};

export default ItemKbis;
