import styled from 'styled-components';

import colors from '../../../config/colors';

export default function (UnStyledFieldError) {
    return styled(UnStyledFieldError)`
        display: inline-block;
        margin-top: 0.25rem;
        font-style: italic;
        color: ${colors.fieldError};
    `;
}
